<template>
    <b-alert v-if="!finished" variant="light" show class="text-center">
        <div v-if="submitted">
            <b-alert variant="success" show>
                <h5>Thank you for your feedback!</h5>

                <div v-if="positive" class="mt-3">
                    Would you be willing to write us a review online? Your
                    feedback helps us show others that our platform is safe, and
                    enjoyable, and can help bring in new users to interact with

                    <b-btn
                        to="/creator/review"
                        variant="primary"
                        pill
                        block
                        class="mt-3"
                    >
                        <font-awesome-icon icon="fa-duotone fa-face-awesome" />
                        Sure, I would be happy to write a review
                    </b-btn>

                    <b-btn
                        @click="finished = true"
                        variant="secondary"
                        pill
                        block
                        class="mt-3"
                    >
                        No, I don't want to write a review
                    </b-btn>
                </div>
            </b-alert>
        </div>
        <div v-else>
            <h5>Are you enjoying the platform?</h5>

            Your feedback is valuable and helps us improve our service

            <div class="mt-3 d-flex justify-content-around">
                <b-btn variant="primary" pill @click="submitRating(1)">
                    <font-awesome-icon icon="fa-light fa-heart" />
                    Yes, I love it!
                </b-btn>
                <b-btn variant="danger" pill @click="submitRating(0)">
                    <font-awesome-icon icon="fa-duotone fa-thumbs-down" />
                    No, I don't like it
                </b-btn>
            </div>
        </div>
    </b-alert>
</template>


<script>
import { mapState } from "vuex";

export default {
    data() {
        return {
            finished: false,
            submitted: false,
            positive: false,
        };
    },
    computed: {
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
        ...mapState("creatorDashboard", ["dashboard"]),
    },
    methods: {
        submitRating(rating) {
            if (rating === 1) {
                this.positive = true;
            }

            this.submitted = true;

            this.api.post("/creatorReview/submit", {
                rating,
            });
        },
    },
};
</script>
