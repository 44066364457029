

<template>
    <div class="p-3 glass position-absolute w-100" style="top: 0; z-index: 1">
        <div class="d-flex align-items-center justify-content-between">
            <h3>Give a Gift</h3>
            <b-btn
                @click="close"
                variant="light"
                class="action-button glass border-0"
            >
                <font-awesome-icon size="lg" icon="fa-light fa-times" />
            </b-btn>
        </div>

        <div class="text-left mt-2">
            Let them know you enjoyed their video and catch their attention with
            a gift
        </div>

        <b-btn-group class="w-100 mt-3">
            <b-btn
                @click="sendGift(giftOption)"
                class="reaction border-top rounded-0 btn-brand bg-white"
                :key="giftOption.price"
                v-for="giftOption in giftOptions"
                variant="light"
            >
                <img class="custom-icon-size icon" :src="giftOption.url" />
                <div class="small reaction-price">
                    <div v-if="giftedItem(giftOption.id)">
                        <font-awesome-icon
                            icon="fa-light fa-check"
                            class="text-primary"
                        />
                    </div>
                    <div
                        v-else
                        class="d-flex align-items-center justify-content-center"
                    >
                        <font-awesome-icon
                            icon="fa-duotone fa-coin"
                            class="mr-1 text-warning"
                        />
                        <div class="text-secondary">
                            {{ giftOption.price }}
                        </div>
                    </div>
                </div>
            </b-btn>
        </b-btn-group>
    </div>
</template>


<style lang="scss" scoped>
.reaction-price {
    margin-top: -5px;
}
</style>

<script>
import { mapGetters, mapState } from "vuex";

export default {
    computed: {
        ...mapState("videoPlayer", ["currentCreatorVideo"]),
        ...mapState("videoIndex", ["sentGiftIds", "favoriteCreatorVideoIds"]),
        ...mapState("options", ["giftOptions"]),
        ...mapGetters("creatorGift", ["hasGiftedItem"]),
    },
    methods: {
        close() {
            this.$store.commit("videoPlayer/setGifting", false);
        },
        giftedItem(giftId) {
            if (
                this.sentGiftIds.find(
                    (sentGift) =>
                        sentGift.giftId === giftId &&
                        sentGift.creatorVideoId ===
                            this.currentCreatorVideo.creatorVideo.id
                )
            ) {
                return true;
            }

            return false;
        },
        sendGift(giftOption) {
            // prevent accidental double gifting
            if (this.giftedItem(giftOption.id)) {
                return;
            }

            this.$store.commit("videoIndex/addSentGiftId", {
                creatorVideoId: this.currentCreatorVideo.creatorVideo.id,
                giftId: giftOption.id,
            });

            this.$store.commit(
                "videoIndex/addGiftedCreatorVideoIds",
                this.currentCreatorVideo.creatorVideo.id
            );

            this.$store.commit(
                "videoIndex/addFavoriteCreatorVideoId",
                this.currentCreatorVideo.creatorVideo.id
            );

            this.api.post(
                `/gift/${this.currentCreatorVideo.creatorVideo.id}/fromVideo/${giftOption.id}`
            );
        },
    },
};
</script>