<template>
    <div class="position-relative">
        <div class="p-2 d-flex align-items-center justify-content-between">
            <h5 class="mb-0">
                <font-awesome-icon icon="fa-duotone fa-bell" size="lg" />
                {{ $t("Activity") }}
            </h5>

            <div>
                <b-dropdown
                    size="sm"
                    variant="light"
                    toggle-class="bg-light rounded-pill border-0 opacity-50"
                    no-caret
                >
                    <template #button-content>
                        <font-awesome-icon icon="fa-solid fa-ellipsis" />
                    </template>

                    <b-dropdown-item @click="markAllSeen">
                        {{ $t("Mark all as Read") }}
                    </b-dropdown-item>
                </b-dropdown>
            </div>
        </div>
        <div>
            <activity-index />
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";

import ActivityIndex from "@/components/activity/ActivityIndex";

export default {
    created() {
        this.$store.dispatch("activity/refresh");
    },
    methods: {
        markAllSeen() {
            this.$store.dispatch("activity/markAllSeen");
        },
    },
    computed: {
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
    },
    components: {
        ActivityIndex,
    },
};
</script>