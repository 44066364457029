<template>
    <div>
        <mobile-subscription />
        <review-prompt v-if="false" />
    </div>
</template>

<script>
import MobileSubscription from "@/components/cordova/MobileSubscription";
import ReviewPrompt from "@/components/cordova/ReviewPrompt";

export default {
    components: {
        MobileSubscription,
        ReviewPrompt,
    },
};
</script>