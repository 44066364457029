import api from "@/service/api";
import router from "@/router";

const defaultVisibility = 'public';

const state = () => ({
    page: 1,
    saving: false,
    hasMorePages: false,
    creatorVideos: null,
    creatorVideo: null,
    error: null,
    days: null,
    message: null,
    insufficientCredits: false,
    schedulePostCount: null,
    visibility: defaultVisibility,
    uploading: false,
    publishing: false,
    recentlySaved: false,
})

const mutations = {
    reset(state) {
        state.error = null;
        state.message = null;
        state.hasMorePages = false;
        state.saving = false;
        state.insufficientCredits = false
        state.visibility = defaultVisibility;
        state.page = 1;
        state.uploading = false;
        state.publishing = false;
        state.recentlySaved = false;
    },
    setMessage(state, value) {
        state.message = value
    },
    setCreatorVideos(state, value) {
        state.creatorVideos = value;
    },
    setCreatorVideo(state, value) {
        state.creatorVideo = value;
    },
    setInsufficientCredits(state, value) {
        state.insufficientCredits = value;
    },
    setHasMorePages(state, value) {
        state.hasMorePages = value;
    },
    setRecentlySaved(state, value) {
        state.recentlySaved = value;
    },
    setVisibility(state, value) {
        state.visibility = value;
    },
    setDays(state, value) {
        state.days = value
    },
    setSchedulePostCount(state, value) {
        state.schedulePostCount = value
    },
    setSaving(state, value) {
        state.saving = value
    },
    setError(state, value) {
        state.error = value
    },
    nextPage(state) {
        state.page++;
    },
    previousPage(state) {
        state.page--;
    },
    setUploading(state, value) {
        state.uploading = value
    },
}

const actions = {
    nextPage({ commit, dispatch }) {
        commit('setCreatorVideos', null)
        commit('nextPage')
        dispatch('refresh')
    },
    previousPage({ commit, dispatch }) {
        commit('setCreatorVideos', null)
        commit('previousPage')
        dispatch('refresh')
    },
    refresh({ commit, state }) {
        api.post('/creatorVideo/index', {
            page: state.page,
        }).then((response) => {
            if (response?.data?.creatorVideos) {
                commit('setCreatorVideos', response.data.creatorVideos)
                commit('setHasMorePages', response.data.hasMorePages)
                commit('setSchedulePostCount', response.data.schedulePostCount)
            }
        });
    },
    create({ dispatch, commit }, { publishedAt, isPrivate } = {}) {
        commit('setInsufficientCredits', false);

        api.post('/creatorVideo/create', {
            publishedAt,
            isPrivate
        }).then((response) => {
            if (response?.data?.creatorVideo) {
                dispatch('onboarding/status', null, { root: true })

                router.push({
                    name: 'creatorVideoEdit',
                    params: {
                        id: response.data.creatorVideo.id,
                    },
                })
            }
        }).catch(error => {
            if (error?.response?.data?.code === 'insufficientCredits') {
                commit('setInsufficientCredits', true);
            }
        })
    },
    get({ commit, dispatch }, { creatorVideoId, refresh } = {}) {
        if (!refresh) {
            commit('reset');
        }

        api.post(`/creatorVideo/${creatorVideoId}/get`).then((response) => {
            if (response?.data?.creatorVideo) {
                dispatch('setCreatorVideo', response.data.creatorVideo)
            }
        });
    },
    refreshCreatorVideo({ state, dispatch }) {
        if (state.creatorVideo) {
            dispatch('get', {
                creatorVideoId: state.creatorVideo.id,
                refresh: true
            })
        }
    },
    setCreatorVideo({ commit }, creatorVideo) {
        commit('setCreatorVideo', creatorVideo)
    },
    refreshSchedule({ commit }) {
        api.post('/creatorVideo/schedule').then((response) => {
            if (response?.data?.days) {
                commit('setDays', response.data.days)
            }
        });
    },
    publish({ state, commit }) {
        commit('setError', null)
        commit('setSaving', true)

        // race condition protection
        if (!state.creatorVideo) {
            commit('reset')
            return;
        }

        api.post(`/creatorVideo/${state.creatorVideo.id}/publish`, {
            message: state.message,
        }).then((response) => {
            if (response?.data?.creatorVideo) {
                commit('setCreatorVideo', response.data.creatorVideo)
            }

            commit('setRecentlySaved', true)

            setTimeout(() => {
                commit('setRecentlySaved', false)
            }, 2000)
        }).catch(error => {
            if (error?.response?.data?.errors?.creatorVideo?.length) {
                commit('setError', error.response.data.errors.creatorVideo.join(","))
            }
        }).then(() => {
            commit('setSaving', false)
        })
    },
    delete({ state }) {
        api.post(`/creatorVideo/${state.creatorVideo.id}/delete`).then(() => {
            router.push({ name: "creatorVideoIndex" });
        })
    },
}

export default {
    namespaced: true,
    state,
    actions,
    mutations
}
