

<template>
    <div v-if="false && currentCreatorVideo">
        <b-btn @click="gift" class="action-button glass glass-smoke my-2">
            <font-awesome-icon
                :class="{ 'text-warning': highlight }"
                icon="fa-duotone fa-gift"
                size="xl"
            />
        </b-btn>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    computed: {
        highlight() {
            return this.giftedCreatorVideoIds.includes(
                this.currentCreatorVideo.creatorVideo.id
            );
        },
        ...mapState("videoPlayer", ["currentCreatorVideo"]),
        ...mapState("videoIndex", ["giftedCreatorVideoIds"]),
    },
    methods: {
        gift() {
            this.$store.commit("videoPlayer/setGifting", true);
        },
    },
};
</script>
