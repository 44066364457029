<template>
    <generic-page>
        <div
            class="d-flex align-items-center justify-content-between mt-3 mb-2"
        >
            <h5 class="mb-0">Customers</h5>
            <div class="d-flex align-items-center">
                <b-dropdown
                    text="Sort"
                    variant="light"
                    right
                    class="rounded-pill bg-white mr-2"
                >
                    <b-dropdown-item @click="setFilter('Earnings')">
                        Earnings
                    </b-dropdown-item>
                    <b-dropdown-item @click="setFilter('Newest')">
                        Newest
                    </b-dropdown-item>
                    <b-dropdown-item @click="setFilter('Most Active')">
                        Most Active
                    </b-dropdown-item>
                </b-dropdown>

                <affiliate-customer-create />
            </div>
        </div>

        <div v-if="users">
            <div
                :key="user.standardMap.id"
                v-for="user in users"
                @click="
                    $router.push({
                        name: 'affiliateCustomerView',
                        params: {
                            id: user.standardMap.id,
                        },
                    })
                "
            >
                <user-standard-avatar
                    :timestamp="user.standardMap.created"
                    :standardMap="user.standardMap"
                    :nonPrimaryPhotos="false"
                    :hideBadges="true"
                    :userBetweenStatus="false"
                    size="small"
                    class="border-light border-top"
                >
                    <div v-if="user.creditRefillTotal" class="text-success">
                        Spent ${{ user.creditRefillTotal }}
                    </div>
                </user-standard-avatar>
            </div>
        </div>
        <div v-else>
            <generic-loading />
        </div>

        <div
            v-if="showPagination"
            class="d-flex align-items-center justify-content-between p-3"
        >
            <div>
                <b-btn
                    @click="previous"
                    variant="light"
                    size="lg"
                    block
                    pill
                    class="p-3 border-0 d-flex align-items-center"
                    :disabled="page === 1"
                >
                    <font-awesome-icon icon="fa-light fa-arrow-left" />
                    <div class="ml-2 d-none d-md-block">
                        {{ $t("Previous") }}
                    </div>
                </b-btn>
            </div>
            <div class="text-center text-secondary">Page {{ page }}</div>
            <div>
                <b-btn
                    @click="next"
                    variant="light"
                    size="lg"
                    block
                    pill
                    class="p-3 border-0 d-flex align-items-center"
                    :disabled="!hasMorePages"
                >
                    <div class="mr-2 d-none d-md-block">
                        {{ $t("Next") }}
                    </div>
                    <font-awesome-icon icon="fa-light fa-arrow-right" />
                </b-btn>
            </div>
        </div>
    </generic-page>
</template>

<script>
import UserStandardAvatar from "@/components/user/UserStandardAvatar";
import AffiliateCustomerCreate from "@/components/affiliate/customer/AffiliateCustomerCreate";

export default {
    data() {
        return {
            users: false,
            hasMorePages: false,
            page: 1,
        };
    },
    created() {
        this.refresh();
    },
    computed: {
        showPagination() {
            if (!this.hasMorePages && this.page === 1) {
                return false;
            }

            return true;
        },
    },
    methods: {
        setFilter(filter) {
            const query = {};

            if (filter) {
                query.filter = filter;
            }

            this.$router
                .push({
                    name: "affiliateCustomers",
                    query,
                })
                .then(() => {
                    this.refresh();
                });
        },
        refresh() {
            this.api
                .post("/invitedUser/affiliate", {
                    page: this.page,
                    filter: this.$route.query?.filter,
                })
                .then((response) => {
                    if (response?.data?.users) {
                        this.users = response.data.users;
                    }

                    this.hasMorePages = response.data.hasMorePages;
                });
        },
        next() {
            this.page++;
            this.$scrollToTop();
            this.refresh();
        },
        previous() {
            this.page--;
            this.$scrollToTop();
            this.refresh();
        },
    },
    components: {
        UserStandardAvatar,
        AffiliateCustomerCreate,
    },
};
</script>