<template>
    <generic-page>
        <back-to-dashboard />

        <div v-if="onboardingUser.creatorIdlePay">
            <creator-livestream-idle-faq />
        </div>

        <b-alert
            v-if="$route.query?.from === 'request'"
            show
            variant="primary"
            class="text-center"
        >
            <h5>You've Been Requested to Go Live!</h5>
            A user has asked you go live, and is now following you. As soon as
            you start your live stream they will be notified to join
            <div>
                <font-awesome-icon icon="fa-light fa-arrow-down" />
            </div>

            <b-btn
                @click="createLivestream"
                variant="light"
                pill
                class="border btn-brand mt-3"
                block
                size="lg"
            >
                <font-awesome-icon
                    icon="fa-solid fa-circle"
                    class="text-danger"
                />
                Go Live
            </b-btn>
        </b-alert>

        <div class="d-flex align-items-center justify-content-between mt-3">
            <h5 class="mb-0">Livestreams</h5>

            <b-button @click="modal = true" variant="light" pill class="border">
                <font-awesome-icon icon="fa-duotone fa-info-circle" />
                Guide
            </b-button>
        </div>

        <b-modal v-model="modal" hide-footer title="Livestream Guide" centered>
            <creator-live :modal="true" />
        </b-modal>

        <div class="py-2">
            <div v-if="loading">
                <generic-loading />
            </div>
            <b-list-group v-else>
                <b-list-group-item
                    :key="creatorLivestream.id"
                    v-for="creatorLivestream in creatorLivestreams"
                    @click="
                        $router.push({
                            name: 'creatorLivestreamView',
                            params: {
                                id: creatorLivestream.defaultMap.id,
                            },
                        })
                    "
                    action
                >
                    {{ creatorLivestream.defaultMap.createdRelative }}
                    <div class="small">
                        {{ creatorLivestream.defaultMap.status }}
                    </div>

                    <div class="small text-secondary">
                        <coin-badge
                            class="mr-1"
                            v-if="creatorLivestream.defaultMap.totalEarnings"
                            :amount="creatorLivestream.defaultMap.totalEarnings"
                        />
                        <span>
                            {{ creatorLivestream.defaultMap.duration }}
                        </span>
                    </div>
                </b-list-group-item>
            </b-list-group>
        </div>

        <generic-pagination
            @next="next"
            @previous="previous"
            :hasMorePages="hasMorePages"
            :page="page"
        />
    </generic-page>
</template>

<script>
import { mapState } from "vuex";

import BackToDashboard from "@/components/utility/BackToDashboard";
import GenericPagination from "@/components/utility/GenericPagination";
import CreatorLive from "@/components/creator/guide/view/CreatorLive";
import CreatorLivestreamIdleFaq from "@/components/creator/livestream/CreatorLivestreamIdleFaq";

export default {
    data() {
        return {
            page: 1,
            hasMorePages: false,
            creatorLivestreams: null,
            loading: false,
            modal: false,
        };
    },
    created() {
        this.refresh();
    },
    computed: {
        ...mapState("options", ["giftOptions", "prices"]),
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
    },
    methods: {
        next() {
            this.page++;
            this.refresh();
        },
        previous() {
            this.page--;
            this.refresh();
        },
        refresh() {
            this.loading = true;

            this.api
                .post("/creatorLivestream/index", {
                    page: this.page,
                })
                .then((response) => {
                    this.creatorLivestreams =
                        response?.data?.creatorLivestreams;
                    this.hasMorePages = response?.data?.hasMorePages;
                })
                .catch(() => {})
                .then(() => {
                    this.loading = false;
                });
        },
        createLivestream() {
            this.$store.dispatch("creatorLivestream/create");
        },
    },
    components: {
        BackToDashboard,
        CreatorLive,
        GenericPagination,
        CreatorLivestreamIdleFaq,
    },
};
</script>