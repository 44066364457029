

<template>
    <div v-if="currentCreatorVideo">
        <b-btn @click="favorite" class="action-button glass glass-smoke my-2">
            <font-awesome-icon
                v-if="isCurrentFavorited"
                icon="fa-solid fa-heart"
                class="text-danger"
                size="xl"
            />
            <font-awesome-icon v-else icon="fa-light fa-heart" size="xl" />
        </b-btn>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    computed: {
        isCurrentFavorited() {
            return (
                this.currentCreatorVideo &&
                this.favoriteCreatorVideoIds?.includes(
                    this.currentCreatorVideo.creatorVideo?.id
                )
            );
        },
        ...mapState("videoPlayer", ["currentCreatorVideo"]),
        ...mapState("videoIndex", ["favoriteCreatorVideoIds"]),
    },
    methods: {
        favorite() {
            if (this.isCurrentFavorited) {
                this.$store.dispatch("videoIndex/unfavorite");
            } else {
                this.$store.dispatch("videoIndex/favorite");
            }
        },
    },
};
</script>
