// naw
// just use constants file
// refactor; start there
// do it right

// make function to combine the id with string or whatever



export const FEED_TYPE_USER_UPDATE = 'userUpdate';
export const FEED_TYPE_CREATOR_POST = 'creatorPost';
export const FEED_TYPE_CREATOR_GALLERY = 'creatorGallery';
export const FEED_TYPE_CREATOR_VIDEO = 'creatorVideo';

const getFeedKey = ({ type, id } = {}) => {
    return `${type}-${id}`
};

export {
    getFeedKey,
};
