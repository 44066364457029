<template>
    <div class="p-2 bg-white rounded-top">
        <div class="d-flex align-items-center justify-content-between">
            <div class="text-truncate mr-3">
                <div
                    class="d-flex align-items-center fake-link"
                    @click="viewProfile"
                >
                    <div class="position-relative mr-2">
                        <img
                            height="50"
                            width="50"
                            class="border border-light rounded flag"
                            :src="standardMap.primaryPhoto.small"
                        />
                        <div class="position-absolute online-status-wrapper">
                            <user-online-status
                                size="small"
                                :standardMap="standardMap"
                            />
                        </div>
                    </div>
                    <div class="">
                        <h6 class="text-dark mb-0">
                            <user-primary-badge
                                class="mr-1"
                                :standardMap="standardMap"
                            />
                            {{ standardMap.name }}
                        </h6>
                        <div
                            class="small text-secondary d-flex align-items-center"
                        >
                            <img class="mr-1" :src="standardMap.flagUrl" />
                            {{ standardMap.location }}
                        </div>
                    </div>
                </div>
            </div>
            <user-follow v-if="!disableFollow" :standardMap="standardMap" />
        </div>
    </div>
</template>

<script>
import UserFollow from "@/components/userHeader/UserFollow";
import UserOnlineStatus from "@/components/user/UserOnlineStatus";
import UserPrimaryBadge from "@/components/user/UserPrimaryBadge";

export default {
    methods: {
        viewProfile() {
            if (this.disableViewProfile) {
                return;
            }

            this.$store.dispatch("profile/viewProfile", {
                userId: this.standardMap.id,
                profile: this.standardMap,
            });
        },
    },
    components: {
        UserFollow,
        UserOnlineStatus,
        UserPrimaryBadge,
    },
    props: ["standardMap", "disableFollow"],
};
</script>