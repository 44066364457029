<template>
    <div>
        <h5>
            <font-awesome-icon
                icon="fa-duotone fa-signal-stream"
                class="text-danger"
            />
            Go Live and Earn!
        </h5>
        Live streaming is the best way to get the most attention, users now have
        a new Live tab where they can instantly see all live streams in
        real-time.

        <div class="mt-2">
            <font-awesome-icon icon="fa-duotone fa-coin" class="text-warning" />
            Earn {{ prices.livestream }} credits per minute for every viewer who
            joins your stream. Our system can handle up to 100 viewers on a
            stream at a time
        </div>

        <b-alert variant="primary" show class="mt-3">
            <font-awesome-icon icon="fa-duotone fa-info-circle" /> Live
            streaming is available upon request from another user. Once they
            request you to go live, you have the option to broadcast your stream
        </b-alert>

        <div class="mt-2">
            Viewers can also send gifts on your stream, here are the different
            gifts and how much you earn for each type of gift
            <b-btn-group class="w-100 mt-3">
                <b-btn
                    class="reaction border-top rounded-0 btn-brand bg-white"
                    :key="giftOption.price"
                    v-for="giftOption in giftOptions"
                    variant="light"
                >
                    <div>
                        {{ giftOption.name }}
                    </div>
                    <img class="custom-icon-size" :src="giftOption.url" />
                    <div class="small reaction-price">
                        <div
                            class="d-flex align-items-center justify-content-center"
                        >
                            <font-awesome-icon
                                icon="fa-duotone fa-coin"
                                class="mr-1 text-warning"
                            />
                            <div class="text-secondary">
                                {{ giftOption.price }}
                            </div>
                        </div>
                    </div>
                </b-btn>
            </b-btn-group>
        </div>

        <b-btn
            v-if="!modal"
            @click="finished"
            variant="primary"
            pill
            block
            size="lg"
            class="mt-3"
        >
            Ok, got it
        </b-btn>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    methods: {
        finished() {
            this.api.post("/userFlag/creatorGuideLive").then(() => {
                this.$store.dispatch("onboarding/status");
                this.$store.dispatch("creatorGuide/refresh");
                this.$store.commit("creatorGuide/reset");
            });
        },
    },
    computed: {
        ...mapState("options", ["prices", "giftOptions"]),
    },
    props: ["modal"],
};
</script>


