
<template>
    <div>
        <div
            id="video-index-swiper"
            class="d-flex flex-column full-height-page-including-submenu"
        >
            <div class="flex-grow-1 d-flex align-items-center">
                <div
                    class="swiper swiper-video shadow position-relative"
                    ref="videoIndexSwiper"
                >
                    <div class="swiper-wrapper">
                        <div
                            class="swiper-slide"
                            v-for="{
                                creatorVideo,
                                user: standardMap,
                            } in creatorVideos"
                            :key="creatorVideo.id"
                        >
                            <div v-if="sharing">
                                <video-share-dialog />
                            </div>

                            <div
                                class="p-3 glass glass-smoke position-absolute w-100"
                                style="top: 0; z-index: 1"
                            >
                                <div
                                    class="d-flex align-items-center justify-content-between"
                                >
                                    <div class="text-truncate mr-3">
                                        <div
                                            class="d-flex align-items-center fake-link"
                                        >
                                            <div class="position-relative mr-2">
                                                <img
                                                    height="50"
                                                    width="50"
                                                    class="border border-light rounded flag"
                                                    :src="
                                                        standardMap.primaryPhoto
                                                            .small
                                                    "
                                                />
                                            </div>
                                            <div>
                                                <h5 class="text-left mb-0">
                                                    {{ standardMap.name }}
                                                </h5>
                                                <div
                                                    class="small d-flex align-items-center"
                                                >
                                                    <img
                                                        class="mr-1 icon"
                                                        :src="
                                                            standardMap.flagUrl
                                                        "
                                                    />
                                                    {{ standardMap.location }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <video-view
                                :withoutMessage="true"
                                :creatorVideo="creatorVideo"
                            />
                        </div>
                        <div class="swiper-slide">
                            <div
                                class="loading d-flex align-items-center justify-content-center h-100"
                            >
                                <div v-if="reachedTheEnd" class="px-3">
                                    <h3>The End</h3>
                                    You've reached the end of the list, check
                                    back later to see new videos, or change your
                                    filters to show more results
                                    <div class="mt-3">
                                        <b-btn
                                            variant="primary"
                                            @click="startOver"
                                            pill
                                        >
                                            Start Over
                                        </b-btn>
                                    </div>
                                </div>
                                <div v-else>
                                    <generic-loading />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="swiper-pagination"></div>
                    <div class="swiper-actions">
                        <video-action-page
                            v-if="swiper"
                            @prevSlide="prevSlide"
                            @nextSlide="nextSlide"
                        />

                        <video-action-share
                            :creatorVideo="currentCreatorVideo"
                        />
                    </div>

                    <div
                        class="position-absolute w-100"
                        style="bottom: 0; z-index: 1"
                    >
                        <video-toolbar />

                        <div
                            class="p-3 glass glass-smoke text-center small pt-2"
                        >
                            Meet Single Women - Worldwide. Thousands Online
                            Daily.
                            <div class="text-success">
                                Signup is Free - No Credit Card Required
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.swiper-actions {
    position: absolute;
    bottom: 80px;
    right: 0;
    z-index: 2;
    width: 4rem;
}
</style>


<script>
import { mapGetters, mapState } from "vuex";

import Swiper, { Mousewheel, Navigation } from "swiper";

import "swiper/swiper-bundle.css";

Swiper.use([Navigation, Mousewheel]);

import VideoView from "@/components/video/VideoView";
import VideoShareDialog from "@/components/video/VideoShareDialog";
import VideoToolbar from "@/components/video/VideoToolbar";
import VideoActionPage from "@/components/video/action/VideoActionPage";
import VideoActionShare from "@/components/video/action/VideoActionShare";

export default {
    data() {
        return {
            swiper: null,
            loading: false,
            unsubscribeToAction: null,
            unsubscribeToMutations: null,
            initialized: false,
            scrollTimeout: null,
        };
    },
    beforeDestroy() {
        this.$mousetrap.unbind("up down");

        if (this.swiper) {
            this.swiper.destroy(true, true); // Destroy Swiper and remove all its events and references
            this.swiper = null;
        }
    },
    mounted() {
        if (this.isLoggedIn) {
            this.$router.push({ name: "videoIndex" });
        }

        if (this.$route.query?.source) {
            this.$store.commit(
                "attribution/setSource",
                this.$route.query?.source
            );
        }

        // must come before refresh
        if (this.$route.params?.slug) {
            this.$store.commit("publicVideoIndex/setPage", 1);
            this.$store.commit(
                "publicVideoIndex/setSlug",
                this.$route.params?.slug
            );
        }

        this.refresh();

        this.$mousetrap.bind("up", () => {
            this.prevSlide();
        });
        this.$mousetrap.bind("down", () => {
            this.nextSlide();
        });
    },
    watch: {
        $route(to) {
            if (to?.params?.slug !== this.slug) {
                console.log("slug change, refresh ");
                this.refresh();
            }
        },
    },
    computed: {
        ...mapGetters("user", ["isLoggedIn"]),
        ...mapState("publicVideoIndex", [
            "creatorVideos",
            "reachedTheEnd",
            "currentSlidePosition",
            "currentCreatorVideo",
            "sharing",
            "page",
            "slug",
            "isPlaying",
        ]),
    },
    methods: {
        nextSlide() {
            if (!this.swiper) {
                return;
            }

            this.swiper.slideNext();
        },
        async prevSlide() {
            if (!this.swiper) {
                return;
            }

            // if at the beginning, jump back to previous cached results
            // and set the cursor at the last slide
            if (this.swiper.isBeginning) {
                if (this.page === 1) {
                    console.log("first slide of first page, non-op");
                    return;
                }

                // temporarily disable playback between page changes
                // otherwise the instant the new slides load, it will
                // start playing the video on slide 0
                // and transition without stopping it
                const originalValue = this.isPlaying;

                this.$store.commit("publicVideoIndex/setIsPlaying", false);

                await this.$store.dispatch("publicVideoIndex/prev");

                // the actual last slide is the loading screen for next page
                // so we want to go to second to last when we're scrolling back
                this.$nextTick(async () => {
                    const lastIndex = this.swiper.slides.length - 2;

                    await this.swiper.slideTo(lastIndex, 0);

                    this.$store.commit(
                        "publicVideoIndex/setIsPlaying",
                        originalValue
                    );
                });
            } else {
                this.swiper.slidePrev();
            }
        },
        async initSwiper() {
            let vueContext = this;

            this.$nextTick(() => {
                let options = {
                    direction: "vertical",
                    slidesPerView: 1,
                    freeMode: false,
                    on: {
                        afterInit() {
                            vueContext.slideChanged(this);
                        },
                        slideChange() {
                            vueContext.slideChanged(this);
                        },
                        reachEnd() {
                            vueContext.endOfResults(this);
                        },
                    },
                };

                if (this.currentSlidePosition) {
                    options.initialSlide = this.currentSlidePosition;
                }

                this.swiper = new Swiper(this.$refs.videoIndexSwiper, options);
            });
        },
        async startOver() {
            await this.$store.dispatch("publicVideoIndex/startOver");
            this.swiper.update();
        },
        next() {
            this.$store.dispatch("publicVideoIndex/next");
        },
        async refresh() {
            await this.$store.dispatch("publicVideoIndex/refresh");
            this.initSwiper();
        },
        slideChanged(swiper) {
            this.$store.dispatch(
                "publicVideoIndex/setCurrentSlidePosition",
                swiper.activeIndex
            );

            this.$store.commit("videoPlayer/setSharing", false);
        },
        async endOfResults() {
            await this.$store.dispatch("publicVideoIndex/next");

            this.swiper.update();
            this.swiper.slideTo(0, 0);
        },
    },
    components: {
        VideoView,
        VideoShareDialog,
        VideoToolbar,
        VideoActionPage,
        VideoActionShare,
    },
};
</script>
