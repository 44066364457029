import api from "@/service/api";

import {
    FINDMATE_AUTH_TOKEN,
} from "@/constant/localStorage";

const state = () => ({
    launchParams: window.location.search,
    hasDeterminedIfAuthTokenAvailable: false,
    hasLoginToken: false,
})

const mutations = {
    setHasDeterminedIfAuthTokenAvailable(state, value) {
        state.hasDeterminedIfAuthTokenAvailable = value
    },
    setHasLoginToken(state, value) {
        state.hasLoginToken = value
    },
}

const actions = {
    async run({ dispatch, rootState }) {
        dispatch('detectDarkMode')

        await dispatch('brand/get', null, { root: true })
        await dispatch('checkForAuthTokenPresence')

        dispatch('startRefreshLoop')

        if (!rootState.system.cordova) {
            dispatch('processLaunchUrl')
        }
    },
    detectDarkMode({ dispatch }) {
        try {
            if (window.matchMedia("(prefers-color-scheme: dark)").matches) {
                // just in case leave this condition when testing locally to force light mode
                // if (process.env.NODE_ENV === 'production') {
                document.querySelector("body").classList.add("dark");
                dispatch("system/enableDarkMode", null, { root: true });
                // }
            }
        } catch (e) {
            console.error(e);
        }
    },
    startRefreshLoop({ commit }) {
        setInterval(() => {
            commit("system/refreshNow", null, { root: true });
        }, 60000);
    },
    processLaunchUrl({ state, dispatch }) {
        let urlParams = new URLSearchParams(state.launchParams);

        const action = urlParams.get("action");
        const premiumSource = urlParams.get("premiumSource");
        const userId = urlParams.get("userId");
        const resetEmail = urlParams.get("resetEmail");
        const loginToken = urlParams.get("t");
        const callId = urlParams.get("callId");
        const userStash = urlParams.get("userStash");

        if (userStash) {
            dispatch("system/appendPostLaunchAction", {
                action: "userStash/restore",
                payload: {
                    id: userStash,
                    uuid: urlParams.get("uuid"),
                }
            }, { root: true });
        }

        if (callId) {
            dispatch("system/appendPostLaunchAction", {
                action: "call/openCall",
                payload: {
                    id: callId,
                    startVideo: true
                }
            }, { root: true });
        }

        if (action === "conversation") {
            dispatch("system/appendPostLaunchAction", {
                action: "profile/viewProfile",
                payload: { userId, view: 'conversation' }
            }, { root: true });
        }

        if (action === "profile") {
            dispatch("system/appendPostLaunchAction", {
                action: "profile/viewProfile",
                payload: { userId }
            }, { root: true });
        }

        if (action === "premium") {
            dispatch("system/appendPostLaunchAction", {
                action: "premium/upgrade",
                payload: { source: premiumSource ?? "premium-shown-onload" }
            }, { root: true });
        }

        if (resetEmail) {
            dispatch("system/appendPostLaunchAction", {
                action: "throttle/resetEmailMessage",
                payload: {}
            }, { root: true });
        }

        if (action === 'creatorDashboard') {
            dispatch("system/appendPostLaunchAction", {
                action: "creatorDashboard/view",
                payload: {}
            }, { root: true });
        }

        if (loginToken) {
            dispatch('handleLoginToken', { loginToken })
        }
    },
    handleLoginToken({ dispatch, rootState, commit }, { loginToken }) {
        // login token should not override auth token
        if (rootState.user.authToken) {
            return;
        }

        api.post('/deeplink/exchangeLoginTokenForAuthToken', { loginToken })
            .then((response) => {
                if (response?.data?.authToken) {
                    dispatch('user/login', response.data.authToken, { root: true })
                }
            })
            .catch(() => { })
            .then(() => {
                commit('setHasLoginToken', false)
            })
    },
    checkForAuthTokenPresence({ state, dispatch, commit }) {
        return new Promise(resolve => {
            const finish = () => {
                commit('setHasDeterminedIfAuthTokenAvailable', true);
                resolve();
            }

            // fudge the order
            // run this one check first
            // to set the variable
            // preventing public app from showing
            let urlParams = new URLSearchParams(state.launchParams);

            const loginToken = urlParams.get("t");

            if (loginToken) {
                commit('setHasLoginToken', true)
            }

            if (localStorage.getItem(FINDMATE_AUTH_TOKEN)) {
                dispatch(
                    "user/login",
                    localStorage.getItem(FINDMATE_AUTH_TOKEN),
                    { root: true }
                ).then(() => {
                    finish();
                })

            } else {
                finish();
            }
        })
    },
}

export default {
    namespaced: true,
    state,
    actions,
    mutations
}
