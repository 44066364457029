<template>
    <div class="d-flex flex-column full-height-page bg-light">
        <div
            v-if="$screen.lg"
            id="left-sidebar"
            class="hide-scrollbars shadow-sm"
        >
            <div class="text-center p-3 d-none d-md-block">
                <brand-link />
            </div>
            <onboarding-status />
        </div>
        <div
            class="width-slim align-items-center d-flex flex-grow-1 justify-content-center"
        >
            <mobile-header>
                <div>
                    <b-btn
                        v-b-toggle.onboarding-sidebar
                        variant="light"
                        class="rounded-0 border-0 bg-white"
                    >
                        <font-awesome-icon icon="fa-light fa-bars" size="2x" />
                    </b-btn>
                </div>
                <div>
                    <brand-link />
                </div>
                <div>
                    <b-btn
                        v-b-toggle.onboarding-sidebar
                        variant="light"
                        class="invisible rounded-0 border-0 bg-white"
                    >
                        <font-awesome-icon icon="fa-light fa-bars" size="2x" />
                    </b-btn>
                </div>
            </mobile-header>

            <div v-if="!$screen.lg">
                <b-sidebar
                    id="onboarding-sidebar"
                    left
                    backdrop
                    shadow
                    no-header
                    v-model="onboardingSidebarVisible"
                >
                    <onboarding-status />
                </b-sidebar>
            </div>

            <div
                v-if="currentStep"
                class="flex-grow-1 d-flex align-items-center"
            >
                <onboarding-step :step="currentStep.slug" />
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";

import OnboardingStatus from "@/components/onboarding/OnboardingStatus";
import OnboardingStep from "@/components/onboarding/OnboardingStep";
import BrandLink from "@/components/utility/BrandLink";
import MobileHeader from "@/components/layout/MobileHeader";

export default {
    created() {
        // social app defaults
        document.body.classList.remove("keep-padding");

        // need affiliate flag
        // and fudge their onboarding here

        // this.api.post("/affiliate/fudgeOnboarding").then(() => {
        //     this.$store.dispatch("onboarding/status");
        // });
    },
    data() {
        return {
            confirmed: false,
        };
    },
    watch: {
        currentStep(step) {
            this.api.post("onboarding/saveStep", {
                step: step?.slug,
            });
        },
    },
    computed: {
        ...mapState("onboarding", ["currentStep"]),
        onboardingSidebarVisible: {
            get() {
                return this.$store.state.interface.onboardingSidebarVisible;
            },
            set(value) {
                this.$store.commit(
                    "interface/setOnboardingSidebarVisible",
                    value
                );
            },
        },
    },
    methods: {},
    components: {
        OnboardingStatus,
        OnboardingStep,
        BrandLink,
        MobileHeader,
    },
};
</script>
        