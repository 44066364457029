// this module is shared between public player and logged in player
// since they both have this same functionality

const state = () => ({
    currentCreatorVideo: null,
    sharing: false,
    gifting: false,
    isPlaying: true,
    isMuted: true,
})


const mutations = {
    reset(state) {
        state.progress = 0
        state.currentTime = 0
        state.sharing = false
        state.gifting = false
    },
    setCurrentCreatorVideo(state, value) {
        state.currentCreatorVideo = value
    },
    setSharing(state, value) {
        if (value) {
            state.gifting = false
        }

        state.sharing = value
    },
    setGifting(state, value) {
        if (value) {
            state.sharing = false
        }

        state.gifting = value
    },
    setIsPlaying(state, value) {
        state.isPlaying = value;
    },
    setIsMuted(state, value) {
        state.isMuted = value;
    },
}

const actions = {

}

export default {
    namespaced: true,
    state,
    actions,
    mutations
}
