

<template>
    <div v-if="creatorVideo">
        <b-btn @click="share" class="action-button glass glass-smoke my-2">
            <font-awesome-icon icon="fa-duotone fa-share" size="xl" />
        </b-btn>
    </div>
</template>

<script>
export default {
    methods: {
        share() {
            this.$store.commit("videoPlayer/setSharing", true);
        },
    },
    props: ["creatorVideo"],
};
</script>
