<template>
    <div>
        <b-modal
            :title="$t(currentGuide?.title)"
            hide-footer
            v-model="modal"
            centered
            no-close-on-esc
            scrollable
            static
        >
            <create-first-post v-if="currentGuide?.key === 'createdPost'" />
            <create-first-gallery
                v-if="currentGuide?.key === 'createdGallery'"
            />
            <creator-invite-guides
                v-if="currentGuide?.key === 'viewedInfluencer'"
            />
            <creator-rules v-if="currentGuide?.key === 'creatorRules'" />
            <creator-paid-share
                v-if="currentGuide?.key === 'creatorPaidShare'"
            />
            <creator-video-call
                v-if="currentGuide?.key === 'creatorVideoCall'"
            />
            <creator-live v-if="currentGuide?.key === 'creatorLive'" />
            <creator-recieve-money
                v-if="currentGuide?.key === 'creatorReceiveMoney'"
            />
            <creator-video v-if="currentGuide?.key === 'creatorVideo'" />
        </b-modal>
    </div>
</template>

<script>
import { mapState } from "vuex";

import CreateFirstPost from "@/components/creator/guide/view/CreateFirstPost";
import CreateFirstGallery from "@/components/creator/guide/view/CreateFirstGallery";
import CreatorInviteGuides from "@/components/creator/guide/view/CreatorInviteGuides";
import CreatorRules from "@/components/creator/guide/view/CreatorRules";
import CreatorPaidShare from "@/components/creator/guide/view/CreatorPaidShare";
import CreatorVideoCall from "@/components/creator/guide/view/CreatorVideoCall";
import CreatorLive from "@/components/creator/guide/view/CreatorLive";
import CreatorVideo from "@/components/creator/guide/view/CreatorVideo";
import CreatorRecieveMoney from "@/components/creator/guide/view/CreatorRecieveMoney";

export default {
    computed: {
        ...mapState("creatorGuide", ["currentGuide", "guides"]),
        modal: {
            get() {
                return this.$store.state.creatorGuide.currentGuide
                    ? true
                    : false;
            },
            set(value) {
                if (!value) {
                    this.$store.commit("creatorGuide/reset");
                    this.$store.dispatch("creatorGuide/refresh");
                }
            },
        },
    },
    components: {
        CreateFirstPost,
        CreateFirstGallery,
        CreatorInviteGuides,
        CreatorVideoCall,
        CreatorRules,
        CreatorVideo,
        CreatorPaidShare,
        CreatorLive,
        CreatorRecieveMoney,
    },
};
</script>
