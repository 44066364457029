<template>
    <div class="position-relative">
        <div class="p-2 d-flex align-items-center justify-content-between">
            <h5 class="mb-0">
                <font-awesome-icon
                    icon="fa-duotone fa-fire"
                    size="lg"
                    class="text-warning"
                />
                Popular
            </h5>
        </div>
        <div>
            <div @click="login" :key="user.id" v-for="user in popularUsers">
                <user-standard-avatar
                    :standardMap="user"
                    :nonPrimaryPhotos="false"
                    :hideBadges="true"
                    :userBetweenStatus="false"
                    :hideOnlineStatus="true"
                    size="small"
                    class="border-light border-top"
                >
                    {{ user.location }}
                </user-standard-avatar>
            </div>
        </div>
    </div>
</template>

<script>
import UserStandardAvatar from "@/components/user/UserStandardAvatar";

export default {
    data() {
        return {
            popularUsers: null,
        };
    },
    created() {
        this.api.post("/brochure/chatWithWomen").then((response) => {
            if (response?.data?.popularUsers) {
                this.popularUsers = response.data.popularUsers;
            }
        });
    },
    methods: {
        login() {
            this.$router.push("/login");
        },
        markAllSeen() {
            this.$store.dispatch("activity/markAllSeen");
        },
    },
    components: {
        UserStandardAvatar,
    },
};
</script>