<template>
    <generic-page>
        <back-to-settings />

        <div class="d-flex align-items-center justify-content-between mt-3">
            <h5 class="mb-0">Video - Favorites</h5>
        </div>

        <div class="py-2">
            <div v-if="loading">
                <generic-loading />
            </div>
            <div v-else>
                <b-list-group>
                    <b-list-group-item
                        action
                        :key="creatorVideo.id"
                        v-for="{ creatorVideo } in creatorVideos"
                        @click="
                            $router.push({
                                name: 'videoIndex',
                                params: {
                                    slug: creatorVideo.slug,
                                },
                            })
                        "
                    >
                        <video-avatar :creatorVideo="creatorVideo" />
                    </b-list-group-item>
                </b-list-group>
            </div>
        </div>

        <generic-pagination
            @next="next"
            @previous="previous"
            :hasMorePages="hasMorePages"
            :page="page"
        />
    </generic-page>
</template>

<script>
import BackToSettings from "@/components/setting/utility/BackToSettings";
import GenericPagination from "@/components/utility/GenericPagination";
import VideoAvatar from "@/components/video/VideoAvatar";

export default {
    data() {
        return {
            page: 1,
            hasMorePages: false,
            creatorVideos: null,
            loading: false,
        };
    },
    created() {
        this.refresh();
    },
    methods: {
        next() {
            this.page++;
            this.refresh();
        },
        previous() {
            this.page--;
            this.refresh();
        },
        refresh() {
            this.loading = true;

            this.api
                .post("/video/index", {
                    page: this.page,
                    view: "favorite",
                })
                .then((response) => {
                    this.creatorVideos = response?.data?.creatorVideos;
                    this.hasMorePages = response?.data?.hasMorePages;
                })
                .catch(() => {})
                .then(() => {
                    this.loading = false;
                });
        },
    },
    components: {
        BackToSettings,
        GenericPagination,
        VideoAvatar,
    },
};
</script>