<template>
    <div @click="openUploadWidget">
        <slot />
    </div>
</template>

<script>
export default {
    created() {
        this.$store.dispatch("cloudinary/loadCloudinaryOnce");
    },
    methods: {
        openUploadWidget() {
            const options = {
                cloud_name: "findmateapp",
                upload_preset: "w9g8lnht",
                // cropping: true, //add a cropping step
                // showAdvancedOptions: true,  //add advanced options (public_id and tag)
                sources: [
                    "local",
                    "url",
                    "camera",
                    "dropbox",
                    "facebook",
                    "instagram",
                    "google_drive",
                ],
                showPoweredBy: false,
            };

            if (this.clientAllowedFormats) {
                options.clientAllowedFormats = this.clientAllowedFormats;
            } else {
                options.clientAllowedFormats = ["image"];
            }

            if (this.creatorVideo) {
                // override default
                options.upload_preset = "creatorVideo";

                options.eager = [
                    {
                        width: 720,
                        height: 1280,
                        crop: "fill",
                        format: "mp4",
                        video_codec: "h264",
                        quality: "auto",
                    },
                    {
                        width: 720,
                        height: 1280,
                        crop: "fill",
                        format: "webm",
                        video_codec: "vp9",
                        quality: "auto",
                    },
                ];

                options.eager_async = true;
            }

            window.cloudinary
                .openUploadWidget(options, (error, result) => {
                    if (!error && result?.event === "success") {
                        this.$emit(
                            "uploadSuccess",
                            result.info.secure_url,
                            result.info
                        );
                    }
                })
                .open();
        },
    },
    props: ["clientAllowedFormats", "creatorVideo"],
};
</script>