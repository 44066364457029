<template>
    <div>
        <b-alert variant="light" show class="p-2">
            <h5>Share your video</h5>

            <b-input-group class="my-1">
                <b-form-input
                    size="sm"
                    :value="creatorVideo.urlVanity"
                    readonly
                />
                <b-input-group-append>
                    <b-btn @click="copyUrl" variant="primary" size="sm">
                        Copy
                    </b-btn>
                </b-input-group-append>
            </b-input-group>

            <div class="small">
                <div v-if="hasCopied" class="text-primary">
                    <font-awesome-icon icon="fa-light fa-check" />
                    URL has been copied, now share your URL on social media,
                    chat apps to earn rewards!
                </div>
            </div>
        </b-alert>
    </div>
</template>

<script>
import { mapState } from "vuex";

import copy from "clipboard-copy";

export default {
    data() {
        return {
            hasCopied: false,
        };
    },

    computed: {
        ...mapState("creatorVideo", ["creatorVideo"]),
    },
    methods: {
        copyUrl() {
            copy(this.creatorVideo.url);
            this.hasCopied = true;
        },
    },
    components: {},
};
</script>