<template>
    <generic-page>
        <back-to-settings />

        <div class="d-flex align-items-center justify-content-between mt-3">
            <h5 class="mb-0">Viewed Profiles</h5>
        </div>

        <div class="py-2">
            <div v-if="loading">
                <generic-loading />
            </div>
            <div v-else>
                <div
                    :key="userVisit.id"
                    v-for="userVisit in userVisits"
                    @click="viewProfile(userVisit.standardMap)"
                >
                    <user-standard-avatar
                        :standardMap="userVisit.standardMap"
                        :timestamp="userVisit.defaultMap.lastVisit"
                        :nonPrimaryPhotos="false"
                        :hideBadges="true"
                        :userBetweenStatus="true"
                        size="small"
                        class="border-light border-top"
                    >
                    </user-standard-avatar>
                </div>
            </div>
        </div>

        <generic-pagination
            @next="next"
            @previous="previous"
            :hasMorePages="hasMorePages"
            :page="page"
        />
    </generic-page>
</template>

<script>
import BackToSettings from "@/components/setting/utility/BackToSettings";
import GenericPagination from "@/components/utility/GenericPagination";
import UserStandardAvatar from "@/components/user/UserStandardAvatar";

export default {
    data() {
        return {
            page: 1,
            hasMorePages: false,
            userVisits: null,
            loading: false,
        };
    },
    created() {
        this.refresh();
    },
    methods: {
        next() {
            this.page++;
            this.refresh();
        },
        previous() {
            this.page--;
            this.refresh();
        },
        viewProfile(standardMap) {
            this.$store.dispatch("profile/viewProfile", {
                userId: standardMap.id,
                profile: standardMap,
            });
        },
        refresh() {
            this.loading = true;

            this.api
                .post("/userVisit/index", {
                    page: this.page,
                })
                .then((response) => {
                    this.userVisits = response?.data?.userVisits;
                    this.hasMorePages = response?.data?.hasMorePages;
                })
                .catch(() => {})
                .then(() => {
                    this.loading = false;
                });
        },
    },
    components: {
        BackToSettings,
        GenericPagination,
        UserStandardAvatar,
    },
};
</script>