

<template>
    <div class="p-3 glass position-absolute w-100" style="top: 0; z-index: 1">
        <div class="d-flex align-items-center justify-content-between">
            <h3>Share Video</h3>
            <b-btn
                @click="closeSharing"
                variant="light"
                class="action-button glass border-0"
            >
                <font-awesome-icon size="lg" icon="fa-light fa-times" />
            </b-btn>
        </div>

        <h5>Share link</h5>

        <b-input-group class="my-1">
            <b-form-input
                :value="currentCreatorVideo.creatorVideo.urlVanity"
                readonly
            />
            <b-input-group-append>
                <b-btn @click="copyUrl" variant="primary"> Copy </b-btn>
            </b-input-group-append>
        </b-input-group>

        <div class="small">
            <div v-if="hasCopied" class="text-">
                <font-awesome-icon icon="fa-light fa-check" />
                URL has been copied
            </div>
        </div>

        <div v-if="qrCode" class="text-center mt-4">
            <h5>Scan QR Code</h5>
            <img
                class="rounded"
                style="
                    height: 200px !important;
                    width: 200px !important;
                    background-color: #fff;
                "
                :src="qrCode"
            />
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";

import copy from "clipboard-copy";

export default {
    data() {
        return {
            qrCode: null,
            hasCopied: false,
        };
    },
    mounted() {
        this.refresh();
    },
    computed: {
        ...mapState("videoPlayer", ["currentCreatorVideo"]),
    },
    methods: {
        closeSharing() {
            this.$store.commit("videoPlayer/setSharing", false);
        },
        copyUrl() {
            copy(this.currentCreatorVideo.creatorVideo.url);
            this.hasCopied = true;
        },
        refresh() {
            this.api
                .post(
                    `/video/${this.currentCreatorVideo.creatorVideo.id}/qrcode`
                )
                .then((response) => {
                    if (response?.data?.qrCode) {
                        this.qrCode = response.data.qrCode;
                    }
                });
        },
    },
};
</script>
