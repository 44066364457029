<template>
    <div @click="minimizeAndViewProfile" class="d-flex align-items-center">
        <img
            class="img-fluid rounded-circle"
            :src="standardMap.primaryPhoto.small"
        />
    </div>
</template>


<script>
export default {
    methods: {
        minimizeAndViewProfile() {
            this.$store.dispatch("profile/viewProfile", {
                userId: this.standardMap.id,
                profile: this.standardMap,
            });
        },
    },
    props: ["standardMap"],
};
</script>