<template>
    <div class="w-100">
        <b-alert variant="warning" show class="text-left">
            <div>
                {{ onboardingUser.invitesRequired }}
                new invite required
            </div>

            <b-button
                v-b-toggle.howThisWorks
                variant="light"
                class="border mt-2"
                size="sm"
                pill
            >
                How this works?
            </b-button>
            <b-collapse id="howThisWorks">
                <div class="mt-3 small">
                    Invite a new paying customer into the app using your link
                    below. Once they buy credits you'll be eligible for account
                    restoration
                </div>
                <div class="mt-3 small">
                    Inviting existing users of our platform or any fake users
                    will lead to account termination, it must be a brand new
                    user who has never used our platform before
                </div>
                <div class="mt-3 small">
                    You cannot invite users from your own country, they must be
                    from a different country
                </div>
                <div class="mt-3 small">
                    After we verify the new invite, your account will be
                    restored, and you'll be allowed back into the platform
                </div>
                <div class="mt-3 small">
                    Read the help section below for more details on how to
                    invite guys
                </div>
            </b-collapse>
        </b-alert>

        <b-table-simple v-if="balance" bordered class="mt-2">
            <tbody>
                <tr>
                    <td class="w-66">
                        {{ $t("Credits") }}
                    </td>
                    <td class="bg-white">
                        {{ balance }}
                    </td>
                </tr>
                <tr>
                    <td>
                        {{ onboardingUser.currency }}
                        {{ $t("Balance") }}
                    </td>
                    <td class="bg-white">
                        <span class="text-success font-weight-bold">
                            {{ balanceLocal }}
                            {{ onboardingUser.currency }}
                        </span>
                    </td>
                </tr>
            </tbody>
        </b-table-simple>

        <creator-invite-url />
    </div>
</template>


<script>
import { mapState } from "vuex";

import CreatorInviteUrl from "@/components/creator/invite/CreatorInviteUrl";

export default {
    computed: {
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
        balance() {
            return this.onboardingUser?.wallet?.balance;
        },
        balanceLocal() {
            return this.onboardingUser?.wallet?.balanceLocal;
        },
    },
    components: {
        CreatorInviteUrl,
    },
};
</script>
