<template>
    <div>
        <slot></slot> <span>{{ timeLeft }}...</span>
    </div>
</template>

<script>
export default {
    data() {
        return {
            timeLeft: this.duration,
        };
    },
    mounted() {
        this.startCountdown();
    },
    methods: {
        startCountdown() {
            const intervalId = setInterval(() => {
                if (this.timeLeft > 0) {
                    this.timeLeft--;
                } else {
                    clearInterval(intervalId);
                }
            }, 1000);
        },
    },

    props: ["duration"],
};
</script>