<template>
    <div class="p-2">
        <div v-if="input" class="mb-2">
            <b-form-textarea
                ref="focus"
                class="hide-scrollbars border-light bg-light"
                v-model="message"
                placeholder="Add a message"
                rows="1"
                max-rows="20"
                tabindex="9"
                maxlength="255"
                :state="state"
                size="lg"
            ></b-form-textarea>
            <b-alert
                variant="danger"
                v-if="error"
                show
                class="rounded-0 border-0 small"
            >
                {{ error }}
            </b-alert>
        </div>

        <b-button
            class="ml-2"
            @click="toggleInput"
            variant="light"
            size="sm"
            pill
        >
            <font-awesome-icon icon="fa-duotone fa-pencil" />
            Message
        </b-button>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    data() {
        return {
            currentSetMessageTimeout: null,
            unsubscribeToAction: null,
            input: false,
        };
    },
    created() {
        this.reset();

        this.unsubscribeToAction = this.$store.subscribeAction({
            after: (action) => {
                if (action.type === "creatorVideo/publish") {
                    this.reset();
                }
            },
        });

        this.unsubscribeToMutations = this.$store.subscribe((mutation) => {
            if (mutation.type === "creatorVideo/reset") {
                this.reset();
            }
        });
    },
    beforeDestroy() {
        if (this.unsubscribeToAction) {
            this.unsubscribeToAction();
        }

        if (this.unsubscribeToMutations) {
            this.unsubscribeToMutations();
        }
    },
    computed: {
        state() {
            if (this.error === null) {
                return null;
            }

            return false;
        },
        message: {
            get() {
                return this.creatorVideo.message;
            },
            set(value) {
                this.$store.commit("creatorVideo/setMessage", value);
            },
        },
        ...mapState("onboarding", ["user"]),
        ...mapState("creatorVideo", [
            "error",
            "publishInProgress",
            "creatorVideo",
        ]),
    },
    methods: {
        toggleInput() {
            this.input = !this.input;

            if (this.input) {
                setTimeout(() => {
                    this.$refs.focus.focus();
                }, 1);
            }
        },
        reset() {
            this.input = false;
        },
    },
};
</script>