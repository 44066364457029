<template>
    <div>
        <search-filters />

        <b-skeleton-wrapper :loading="searchLoading">
            <template #loading>
                <div :key="n" v-for="n in Array(10)">
                    <div class="user-wrapper border-top border-light">
                        <div class="user bg-white">
                            <div class="d-flex align-items-center">
                                <div class="mr-3">
                                    <b-skeleton-img
                                        height="150px"
                                        width="150px"
                                    ></b-skeleton-img>
                                </div>
                                <div class="flex-grow-1 p-3">
                                    <h5>
                                        <b-skeleton width="45%"></b-skeleton>
                                    </h5>
                                    <div class="small mt-3">
                                        <div class="text-truncate">
                                            <b-skeleton
                                                width="85%"
                                            ></b-skeleton>
                                        </div>
                                    </div>
                                    <div class="mt-3 d-flex">
                                        <b-skeleton
                                            class="mr-1"
                                            height="32px"
                                            width="32px"
                                            type="avatar"
                                        ></b-skeleton>
                                        <b-skeleton
                                            class="mr-1"
                                            height="32px"
                                            width="32px"
                                            type="avatar"
                                        ></b-skeleton>
                                        <b-skeleton
                                            class="mr-1"
                                            height="32px"
                                            width="32px"
                                            type="avatar"
                                        ></b-skeleton>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>

            <div class="flex-grow-1">
                <div
                    @click="viewProfile(searchUser)"
                    :key="searchUser.standardMap.id"
                    v-for="searchUser in filteredUsers"
                    class="user-wrapper border-top border-light"
                >
                    <user-standard-avatar
                        :nonPrimaryPhotos="true"
                        :userBetweenStatus="true"
                        :timestamp="searchUser.standardMap.lastActive"
                        :standardMap="searchUser.standardMap"
                        class="border-light border-top"
                        :class="{
                            active:
                                view === 'conversation' &&
                                message.userId === openConversationUserId,
                        }"
                    >
                        <search-index-content :searchUser="searchUser" />
                    </user-standard-avatar>
                </div>
            </div>
        </b-skeleton-wrapper>

        <search-pagination />
        <search-scroll-top />
    </div>
</template>

<style lang="scss" scoped>
.fake-avatar {
    height: 150px;
    width: 150px;
    background-color: var(--secondary);
}
</style>

<script>
import { mapGetters, mapState } from "vuex";

import SearchPagination from "@/components/widget/search/SearchPagination";
import SearchFilters from "@/components/widget/search/SearchFilters";
import SearchScrollTop from "@/components/widget/search/SearchScrollTop";
import SearchIndexContent from "@/components/widget/search/SearchIndexContent";
import UserStandardAvatar from "@/components/user/UserStandardAvatar";

export default {
    created() {
        this.$store.dispatch("search/loadInitial");
        window.addEventListener("scroll", this.scrollHandler);
    },
    destroyed() {
        window.removeEventListener("scroll", this.scrollHandler);
    },
    computed: {
        ...mapGetters("search", ["filteredUsers", "isDefaultSearchView"]),
        ...mapState("search", [
            "limitedResults",
            "searchLoading",
            "page",
            "hasScrolledToPagination",
        ]),
        ...mapState("search", ["searchView", "searchCountryId"]),
        ...mapState("navigation", ["view"]),
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
    },
    methods: {
        scrollHandler() {
            const pagination = document.getElementById("search-pagination");

            if (window.scrollY + innerHeight > pagination?.offsetTop) {
                this.$store.commit("search/setHasScrolledToPagination", false);
            } else {
                this.$store.commit("search/setHasScrolledToPagination", true);
            }
        },
        viewProfile(user) {
            this.$store.dispatch("profile/viewProfile", {
                userId: user.standardMap.id,
                profile: user.standardMap,
            });
        },
    },
    components: {
        SearchPagination,
        SearchFilters,
        SearchScrollTop,
        SearchIndexContent,
        UserStandardAvatar,
    },
};
</script>