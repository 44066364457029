<template>
    <div class="p-2">
        <back-to-dashboard />

        <div v-if="buyerEscrows?.length">
            <b-alert variant="warning" show>
                <h5>Your Credits are Pending</h5>
                The earnings shown below came from an new and unverified user.
                Once we're able to verify the user, the credits will be released
                to you instantly

                <div class="mt-3">
                    We have already been notified, there is no need to contact
                    support. We will review this case as soon as possible,
                    usually within 48 hours
                </div>
                <div class="mt-3">
                    We will email you to let you know as soon as your credits
                    are available, thanks for your patience
                </div>
            </b-alert>

            <h5>Pending Credits</h5>

            <b-table-simple bordered class="mb-0">
                <tbody>
                    <tr>
                        <th>Items in Escrow</th>
                        <td class="bg-white">
                            {{ buyerEscrows.length }}
                        </td>
                    </tr>
                    <tr>
                        <th>Credit Pending</th>
                        <td class="bg-white">
                            <coin-badge
                                v-if="dashboard?.pendingCreditCount"
                                :amount="dashboard?.pendingCreditCount"
                            />
                        </td>
                    </tr>
                    <tr>
                        <th>Local Currency</th>
                        <td class="bg-white">
                            {{ dashboard?.pendingCreditLocalCurrency }}
                        </td>
                    </tr>
                </tbody>
            </b-table-simple>

            <h5 class="mt-3">Items in Escrow</h5>

            <b-table-simple bordered class="mb-0">
                <tbody>
                    <tr
                        :key="buyerEscrow.id"
                        v-for="buyerEscrow in buyerEscrows"
                    >
                        <th class="bg-white" style="width: 1%">
                            <coin-badge
                                :amount="buyerEscrow.amount"
                                class="mr-1 border"
                            />
                        </th>
                        <td>
                            {{ buyerEscrow.description }}
                        </td>
                    </tr>
                </tbody>
            </b-table-simple>
        </div>
        <div v-else>
            <b-alert variant="success" show> No pending credits </b-alert>
        </div>
    </div>
</template>

<script>
import BackToDashboard from "@/components/utility/BackToDashboard";
import { mapState } from "vuex";

export default {
    data() {
        return {
            creatorRequests: null,
            buyerEscrows: null,
        };
    },
    mounted() {
        this.refresh();
    },
    computed: {
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
        ...mapState("creatorDashboard", ["dashboard"]),
    },
    methods: {
        viewProfile(profile) {
            this.$store.dispatch("profile/viewProfile", {
                userId: profile.id,
                profile,
            });
        },
        async enableRequests() {
            await this.api.post("/userFlag/creatorEnableRequests");
            this.$store.dispatch("onboarding/status");
        },
        async disableRequests() {
            if (
                confirm(
                    this.$t(
                        "Are you sure? Your earnings may go down as a result"
                    )
                )
            ) {
                await this.api.post("/userFlag/creatorDisableRequests");
                this.$store.dispatch("onboarding/status");
            }
        },
        async refresh() {
            const { data } = await this.api.post("/pendingCredit/buyerEscrow");
            this.buyerEscrows = data.buyerEscrows;
        },
    },
    components: {
        BackToDashboard,
    },
};
</script>