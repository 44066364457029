<template>
    <div class="p-3">
        <b-alert variant="primary" show>
            <h5>
                <font-awesome-icon icon="fa-duotone fa-shield-check" />
                Verification
            </h5>
            <div>
                Profile verification ensures users are genuine and using the app
                as themselves. We have a strict zero-tolerance policy against
                fake profiles.
            </div>
        </b-alert>
        <div :key="video.id" v-for="video in profile.videos">
            <div class="d-flex align-items-center justify-content-between">
                <div>
                    Submitted
                    {{ video.createdRelative }}
                </div>

                <div>
                    <h5 v-if="video.code" class="mb-0 text-primary">
                        {{ video.code }}
                    </h5>
                    <h5 v-else class="mb-0 text-secondary">Phrase</h5>
                </div>
            </div>

            <video-player
                :muted="false"
                :controls="true"
                :autoplay="false"
                :mp4Url="video.mp4Url"
            />
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";

import VideoPlayer from "@/components/utility/VideoPlayer";

export default {
    computed: {
        ...mapState("profile", ["profile"]),
    },
    components: {
        VideoPlayer,
    },
};
</script>