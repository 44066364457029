<template>
    <generic-page>
        <back-to-dashboard />
        <creator-video-view-schedule />

        <creator-video-payout />
        <creator-video-rules />

        <div class="d-flex align-items-center justify-content-between mt-3">
            <h5 class="mb-0">Videos</h5>

            <div class="d-flex">
                <creator-video-help />
                <b-btn @click="createPost" variant="primary" pill class="ml-2">
                    <font-awesome-icon icon="fa-light fa-plus" />
                    Create
                </b-btn>
            </div>
        </div>

        <div class="py-2">
            <b-list-group v-if="creatorVideos">
                <b-list-group-item
                    :key="creatorVideo.id"
                    v-for="creatorVideo in creatorVideos"
                    @click="
                        $router.push({
                            name: 'creatorVideoEdit',
                            params: {
                                id: creatorVideo.id,
                            },
                        })
                    "
                    action
                    class="d-flex align-items-center"
                >
                    <div v-if="creatorVideo.thumbnailUrl" class="mr-3">
                        <img
                            class="img-fluid"
                            :src="creatorVideo.thumbnailUrl"
                        />
                    </div>
                    <div class="text-truncate flex-grow-1">
                        <div class="small text-secondary">
                            <font-awesome-icon
                                icon="fa-solid fa-circle"
                                :class="`text-${creatorVideo.variant}`"
                                size="xs"
                            />

                            <span v-if="creatorVideo.publishDateTerse">
                                {{ creatorVideo.publishDateTerse }}
                            </span>
                            <span v-else> Draft </span>
                        </div>

                        <div v-if="false" class="">Rank 5/348</div>

                        <div class="d-flex align-items-center mt-2">
                            <coin-badge
                                class="mr-1"
                                v-if="creatorVideo.totalCreditsEarned"
                                :amount="creatorVideo.totalCreditsEarned"
                            />
                        </div>
                    </div>
                </b-list-group-item>
            </b-list-group>
            <div v-else>
                <generic-loading />
            </div>
        </div>

        <creator-video-pagination />
    </generic-page>
</template>

<script>
import { mapState } from "vuex";

import BackToDashboard from "@/components/utility/BackToDashboard";
import CreatorVideoPagination from "@/components/creator/video/CreatorVideoPagination";
import CreatorVideoViewSchedule from "@/components/creator/video/CreatorVideoViewSchedule";
import CreatorVideoRules from "@/components/creator/video/CreatorVideoRules";
import CreatorVideoPayout from "@/components/creator/video/CreatorVideoPayout";
import CreatorVideoHelp from "@/components/creator/video/CreatorVideoHelp";

export default {
    created() {
        this.$store.dispatch("creatorVideo/refresh");
    },
    computed: {
        ...mapState("creatorVideo", ["creatorVideos"]),
        ...mapState("options", ["prices", "config"]),
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
    },
    methods: {
        createPost() {
            this.$store.dispatch("creatorVideo/create");
        },
    },
    components: {
        BackToDashboard,
        CreatorVideoPagination,
        CreatorVideoViewSchedule,
        CreatorVideoRules,
        CreatorVideoHelp,
        CreatorVideoPayout,
    },
};
</script>