<template>
    <div>
        <div v-if="!onboardingUser?.censored && userIsLive(standardMap.id)">
            <b-badge variant="danger" pill> LIVE </b-badge>
        </div>
        <div v-else class="user-online-status" :class="statusClass"></div>
    </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";

export default {
    computed: {
        statusClass() {
            if (this.onlineUserIds.includes(this.standardMap.id)) {
                return "online";
            }

            const now = this.$date();
            const lastActive = this.$date(this.standardMap.lastActive);

            const differenceInMinutes = now.diff(lastActive, "minute");

            if (differenceInMinutes < 60) {
                return "away";
            }

            return "offline";
        },
        ...mapState("pusher", ["onlineUserIds"]),
        ...mapGetters("pusher", ["userIsLive"]),
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
    },
    props: ["standardMap", "size"],
};
</script>