<template>
    <div>
        <div class="d-flex align-items-center justify-content-between">
            <h5 class="mb-0">Transaction History</h5>
            <b-btn variant="light" pill @click="refreshTransactions">
                <font-awesome-icon icon="fa-light fa-arrows-rotate" />
            </b-btn>
        </div>
        <b-table-simple striped class="mt-2">
            <thead>
                <th>Summary</th>
                <th class="text-center">Amount</th>
            </thead>
            <tbody>
                <tr :key="transaction.id" v-for="transaction in transactions">
                    <td>
                        <b-badge variant="light" pill>
                            {{ transaction.createdRelative }}
                        </b-badge>
                        {{ transaction.description }}
                    </td>
                    <td
                        :class="`text-${transaction.variant}`"
                        class="text-center"
                    >
                        {{ transaction.amount }}
                    </td>
                </tr>
            </tbody>
        </b-table-simple>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    data() {
        return {};
    },
    mounted() {
        this.refreshTransactions();
    },
    computed: {
        ...mapState("wallet", ["purchaseInProgress", "transactions"]),
    },
    methods: {
        refreshTransactions() {
            this.$store.dispatch("wallet/refreshTransactionHistory");
            this.$store.dispatch("onboarding/status");
        },
    },
    components: {},
};
</script>