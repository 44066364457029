<template>
    <div class="p-3">
        <div v-if="sentRequest" class="text-center py-3">
            <font-awesome-icon
                size="5x"
                class="text-success"
                icon="fa-light fa-check-circle"
            />
            <div class="mt-1 lead">
                {{ $t("Your request was sent") }}
            </div>

            <div class="mt-3">
                <b-btn
                    @click="closeModal"
                    variant="primary"
                    size="lg"
                    block
                    pill
                >
                    {{ $t("Close") }}
                </b-btn>
                <b-btn
                    @click="viewAllRequests"
                    variant="light"
                    size="lg"
                    block
                    pill
                    class="mt-3"
                >
                    {{ $t("View all requests") }}
                </b-btn>
            </div>
        </div>
        <div v-else>
            <b-alert
                v-if="!onboardingUser.guideHowRequestWorksCompleted"
                variant="warning"
                show
            >
                <h5>
                    {{ $t("How requests work?") }}
                </h5>
                <div>
                    {{
                        $t(
                            "Send credits to a creator in exchange for your custom requests. They will be presented with your request and price, if they accept and complete the request the credits will be transferred to their wallet"
                        )
                    }}
                </div>

                <h5 class="mt-3">
                    {{ $t("What if they don't complete the request?") }}
                </h5>
                <div>
                    {{
                        $t(
                            "Incomplete requests will automatically be refunded if the creator does not complete the request, they have 1 week to complete any pending requests or the credits will be transferred back to your wallet"
                        )
                    }}
                </div>

                <h5 class="mt-3">
                    {{ $t("What if I'm not satisfied with the result?") }}
                </h5>
                <div>
                    If you don't accept the content, you can create a dispute,
                    which will give them a chance to correct their submission.
                    If after disputing you still don't get what you're looking
                    for, you will be refunded
                </div>

                <b-btn variant="primary" @click="hideInfo" pill class="mt-3">
                    {{ $t("Ok, got it") }}
                </b-btn>
            </b-alert>

            <div class="d-flex align-items-center justify-content-between">
                {{ $t("Request Details") }}
                <b-btn
                    v-if="onboardingUser.guideHowRequestWorksCompleted"
                    @click="showInfo"
                    variant="light"
                    class="bg-white"
                    pill
                    size="sm"
                >
                    <font-awesome-icon icon="fa-duotone fa-info-circle" />
                    {{ $t("How it works?") }}
                </b-btn>
            </div>

            <div class="mt-1">
                <b-textarea
                    v-model="request"
                    tabindex="1"
                    size="lg"
                    maxlength="252"
                    autocomplete="off"
                    placeholder="Enter request instructions, what would you like from this creator?"
                ></b-textarea>
            </div>
            <div class="mt-3">
                <div
                    class="d-flex align-items-center justify-content-between mt-3 mb-1"
                >
                    {{ $t("Price Offered") }}
                    <wallet-balance />
                </div>

                <b-form-input
                    size="lg"
                    v-model="price"
                    :placeholder="$t('20 - 1000')"
                    inputmode="numeric"
                    autocorrect="off"
                    autocapitalize="none"
                    autocomplete="off"
                ></b-form-input>
            </div>

            <div class="mt-3">
                <b-alert variant="danger" show v-if="error">
                    {{ error }}
                </b-alert>

                <b-btn
                    :disabled="!canSubmitRequest || saving"
                    @click="submitRequest"
                    variant="primary"
                    size="lg"
                    block
                    pill
                >
                    {{ $t("Submit Request") }}
                </b-btn>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";
// import { mapState } from "vuex";

import WalletBalance from "@/components/utility/WalletBalance";

export default {
    data() {
        return {
            saving: false,
            price: null,
            error: null,
            request: null,
            unsubscribeToAction: null,
        };
    },
    mounted() {
        this.unsubscribeToAction = this.$store.subscribeAction({
            after: (action) => {
                if (action.type === "creatorRequest/start") {
                    this.price = null;
                    this.request = null;
                }
            },
        });
    },
    beforeDestroy() {
        if (this.unsubscribeToAction) {
            this.unsubscribeToAction();
        }
    },
    methods: {
        setPrice(price) {
            this.price = price;
        },
        async hideInfo() {
            await this.api.post("/userFlag/guideHowRequestWorksFinished");
            this.$store.dispatch("onboarding/status");
        },
        async showInfo() {
            await this.api.post("/userFlag/guideHowRequestWorksReset");
            this.$store.dispatch("onboarding/status");
        },
        async submitRequest() {
            this.saving = true;
            this.error = null;

            this.api
                .post("/creatorRequest/create", {
                    price: this.price,
                    request: this.request,
                    creatorUserId: this.creatorStandardMap.id,
                })
                .then(() => {
                    this.$store.commit("creatorRequest/reset");
                    this.$store.commit("creatorRequest/setSentRequest", true);
                    this.$store.dispatch("onboarding/status");
                })
                .catch((error) => {
                    if (error?.response?.data?.message) {
                        this.error = error?.response?.data?.message;
                    }
                })
                .then(() => {
                    this.saving = false;
                });
        },
        closeModal() {
            this.$store.commit(
                "interface/setCreatorRequestModalVisible",
                false
            );
        },
        viewAllRequests() {
            this.closeModal();
            this.$router.push({ name: "accountCreatorRequests" });
        },
    },
    computed: {
        canSubmitRequest() {
            if (!this.complete) {
                return false;
            }

            return true;
        },
        complete() {
            return this.price && this.request;
        },
        ...mapState("creatorRequest", ["creatorStandardMap", "sentRequest"]),
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
    },
    components: {
        WalletBalance,
    },
};
</script>
