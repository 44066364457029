<template>
    <generic-page>
        <h5 class="mt-3">Review Guidelines</h5>

        <div v-if="confirm">
            <b-alert variant="primary" show>
                <font-awesome-icon icon="fa-duotone fa-info-circle" />
                Here are just some sample ideas for reviews that are acceptable,
                feel free to write it in your own words:
            </b-alert>
            <h5 class="mt-4 text-success">
                <font-awesome-icon icon="fa-duotone fa-thumbs-up" />
                The app is easy to use, and I met plenty of nice people here,
                love it!
            </h5>
            <h5 class="mt-4 text-success">
                <font-awesome-icon icon="fa-duotone fa-thumbs-up" />
                I like the video calling, its a nice way to see people are real,
                and can meet new friends from all over the world
            </h5>
            <h5 class="mt-4 text-success">
                <font-awesome-icon icon="fa-duotone fa-thumbs-up" />
                I met my partner here, we are already making plans to meet in
                person soon, the app makes it easy to find someone, thank you
            </h5>

            <b-btn
                variant="primary"
                @click="writeReview"
                pill
                block
                class="mt-4"
            >
                <h5 class="mb-0">Ok, I'm ready to review</h5>

                <div class="mt-1 small">
                    This will direct you to the review website on Google
                </div>
            </b-btn>
        </div>
        <div v-else>
            <b-alert variant="warning" show>
                <font-awesome-icon icon="fa-duotone fa-shield-check" />
                Protecting user privacy is very important to us, please review
                the guidelines to ensure your review is compliant
            </b-alert>
            <div class="mt-2">
                <font-awesome-icon icon="fa-solid fa-ban" class="text-danger" />
                Do not discuss anything related to money, withdraw, credits,
                please just talk about the app itself, not about earning
            </div>
            <div class="mt-2">
                <font-awesome-icon icon="fa-solid fa-ban" class="text-danger" />
                Don't share any private information about other users, or
                mention anyone by name
            </div>
            <div class="mt-2">
                <font-awesome-icon icon="fa-solid fa-ban" class="text-danger" />
                Do not post any screenshots of the app, especially any
                containing anyones private information like photos or names
            </div>

            <b-btn
                @click="confirm = true"
                block
                pill
                variant="primary"
                class="mt-3"
            >
                I agree to follow the review guidelines
            </b-btn>
        </div>
    </generic-page>
</template>


<script>
import { mapState } from "vuex";

export default {
    data() {
        return {
            confirm: false,
        };
    },
    computed: {
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
        ...mapState("creatorDashboard", ["dashboard"]),
    },
    methods: {
        writeReview() {
            const query = encodeURIComponent(
                "site:trustpilot.com findmate.app"
            );

            const url = `https://www.google.com/search?q=${query}&num=1`;

            this.$store.dispatch("system/openBrowser", url);
        },
    },
};
</script>
