<template>
    <div class="bg-light">
        <mobile-header>
            <div class="pl-2">
                <brand-link />
            </div>
            <div>
                <b-btn
                    v-b-toggle.right-sidebar
                    variant="light"
                    class="rounded-0 mr-1 bg-white border-0 position-relative"
                >
                    <font-awesome-icon
                        icon="fa-duotone fa-fire"
                        size="lg"
                        class="text-warning"
                    />
                </b-btn>
            </div>
        </mobile-header>
        <div
            v-if="$screen.lg"
            id="left-sidebar"
            class="hide-scrollbars shadow-sm"
            ref="messageIndex"
        >
            <div
                class="px-3 d-flex align-items-center justify-content-between mt-3"
            >
                <brand-link />
                <b-btn-group>
                    <b-btn @click="$router.back()" variant="light">
                        <font-awesome-icon icon="fa-light fa-chevron-left" />
                    </b-btn>
                    <b-btn @click="$router.forward()" variant="light">
                        <font-awesome-icon icon="fa-light fa-chevron-right" />
                    </b-btn>
                </b-btn-group>
            </div>

            <div class="mt-2">
                <div class="p-3">
                    <h5>Welcome!</h5>

                    <div class="">
                        {{ brand.description }}
                    </div>

                    <b-btn
                        class="mt-3"
                        to="/signup"
                        variant="primary"
                        pill
                        block
                        size="lg"
                    >
                        Free Signup
                    </b-btn>
                </div>
            </div>
        </div>

        <div id="main" class="d-block">
            <div class="sticky-top">
                <primary-navigation />
            </div>
            <router-view v-if="$route.meta.public"></router-view>
        </div>
        <div
            v-if="$screen.lg"
            id="right-sidebar"
            class="hide-scrollbars shadow-sm"
        >
            <right-sidebar />
        </div>
        <div v-if="!$screen.lg">
            <b-sidebar
                v-model="rightSidebarVisible"
                id="right-sidebar"
                right
                backdrop
                shadow
                no-header
            >
                <right-sidebar />
            </b-sidebar>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.unread-count {
    background-color: var(--primary);
    font-size: 0.6rem;
    color: #fff;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    height: 19px;
    width: 19px;
    position: absolute;
    top: 0;
    right: 0;
}
</style>


<script>
import { mapState } from "vuex";

import MobileHeader from "@/components/layout/MobileHeader";
import BrandLink from "@/components/utility/BrandLink";
import PrimaryNavigation from "@/components/navigation/PrimaryNavigation";
import RightSidebar from "@/components/public/RightSidebar";

export default {
    created() {
        // default route
        // if (this.$route.name === "fallback") {
        //     const defaultRoute = this.$router
        //         .getRoutes()
        //         .find((route) => route.meta?.default);

        //     if (defaultRoute) {
        //         this.$router.push(defaultRoute);
        //     }
        // }

        // social app defaults
        document.body.classList.remove("keep-padding");
    },
    computed: {
        ...mapState("navigation", ["view"]),
        ...mapState("brand", ["brand"]),
        ...mapState("system", ["finishedInitialLoad", "cordova"]),
        leftSidebarVisible: {
            get() {
                return this.$store.state.interface.leftSidebarVisible;
            },
            set(value) {
                this.$store.commit("interface/setLeftSidebarVisible", value);
            },
        },
        rightSidebarVisible: {
            get() {
                return this.$store.state.interface.rightSidebarVisible;
            },
            set(value) {
                this.$store.commit("interface/setRightSidebarVisible", value);
            },
        },
    },
    methods: {},
    components: {
        BrandLink,
        MobileHeader,
        PrimaryNavigation,
        RightSidebar,
    },
};
</script>
        