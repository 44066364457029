<template>
    <div>
        <video-view
            simplePlayer="true"
            fluid="true"
            :creatorVideo="creatorVideo"
        />
    </div>
</template>

<script>
import { mapState } from "vuex";

import VideoView from "@/components/video/VideoView";

export default {
    computed: {
        ...mapState("creatorVideo", ["creatorVideo"]),
    },
    components: {
        VideoView,
    },
};
</script>