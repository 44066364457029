<template>
    <div>
        <div v-if="schedulePostCount !== null">
            <b-alert
                v-if="schedulePostCount"
                variant="primary"
                show
                class="mb-0 d-flex align-items-center justify-content-between"
            >
                <div>
                    {{ schedulePostCount }}
                    Scheduled
                </div>

                <b-btn
                    to="/creator/video/schedule"
                    variant="light"
                    pill
                    size="sm"
                >
                    View Schedule
                </b-btn>
            </b-alert>
            <b-alert
                v-else
                variant="warning"
                show
                class="mb-0 d-flex align-items-center justify-content-between"
            >
                <div>
                    <font-awesome-icon
                        icon="fa-duotone fa-triangle-exclamation"
                    />
                    No videos scheduled
                </div>

                <b-btn
                    to="/creator/video/schedule"
                    variant="light"
                    pill
                    size="sm"
                >
                    View Schedule
                </b-btn>
            </b-alert>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    computed: {
        ...mapState("creatorPost", [
            "creatorPosts",
            "schedulePostCount",
            "insufficientCredits",
        ]),
    },
};
</script>