<template>
    <div v-if="false">
        <h5>Video Rules</h5>
        <div>
            Videos must be between
            {{ config.creatorVideoMinimumDurationSeconds }}
            and
            {{ config.creatorVideoMaximumDurationSeconds }}
            seconds
        </div>
        <div>No sexual/explicit/adult content</div>
        <div>Your face must be visible at some point in the video</div>
        <div>Video must not be uploaded to other platforms/websites</div>
    </div>
</template>


<script>
import { mapState } from "vuex";

export default {
    computed: {
        ...mapState("options", ["prices", "config"]),
    },
};
</script>