<template>
    <generic-page>
        <b-btn variant="link" size="lg" class="pl-0" to="/creator/video/index">
            <font-awesome-icon size="lg" icon="fa-light fa-chevron-left" />
            All Videos
        </b-btn>

        <div v-if="creatorVideo">
            <div class="bg-white">
                <div
                    class="p-2 d-flex align-items-center justify-content-between"
                >
                    <creator-video-schedule />
                    <font-awesome-icon icon="fa-solid fa-circle" size="xs" />
                </div>

                <div v-if="creatorVideo.processed" class="position-relative">
                    <creator-video-preview />
                    <div
                        class="position-absolute w-100"
                        style="bottom: 1rem; left 1rem;"
                    >
                        <creator-video-message />
                    </div>
                </div>
                <div v-else>
                    <creator-video-upload />
                </div>

                <creator-video-actions />

                <div v-if="creatorVideo.approved">
                    <creator-video-share />
                    <creator-video-ranking v-if="false" />
                    <creator-video-earnings v-if="false" />
                </div>
                <div v-else-if="creatorVideo.published">
                    <b-alert variant="warning" show>
                        Video is pending review
                    </b-alert>
                </div>
            </div>
        </div>
        <div v-else>
            <generic-loading />
        </div>
    </generic-page>
</template>

<script>
import { mapState } from "vuex";

import CreatorVideoSchedule from "@/components/creator/video/CreatorVideoSchedule";
import CreatorVideoMessage from "@/components/creator/video/CreatorVideoMessage";
import CreatorVideoActions from "@/components/creator/video/CreatorVideoActions";
import CreatorVideoUpload from "@/components/creator/video/CreatorVideoUpload";
import CreatorVideoPreview from "@/components/creator/video/CreatorVideoPreview";
import CreatorVideoEarnings from "@/components/creator/video/CreatorVideoEarnings";
import CreatorVideoRanking from "@/components/creator/video/CreatorVideoRanking";
import CreatorVideoShare from "@/components/creator/video/CreatorVideoShare";

export default {
    data() {
        return {};
    },
    created() {
        if (this.$route.params?.id) {
            this.$store.dispatch("creatorVideo/get", {
                creatorVideoId: this.$route.params?.id,
            });
        }
    },
    watch: {
        $route(to) {
            if (
                to?.params?.id !==
                this.$store.state.creatorVideo.creatorVideo?.id
            ) {
                this.$store.dispatch("creatorVideo/get", {
                    creatorVideoId: this.$route.params?.id,
                });
            }
        },
    },
    computed: {
        ...mapState("creatorVideo", ["creatorVideo"]),
    },
    methods: {
        postHelp() {
            this.$store.commit("interface/setPostHelpModalVisible", true);
        },
    },
    components: {
        CreatorVideoSchedule,
        CreatorVideoMessage,
        CreatorVideoActions,
        CreatorVideoUpload,
        CreatorVideoPreview,
        CreatorVideoEarnings,
        CreatorVideoRanking,
        CreatorVideoShare,
    },
};
</script>
