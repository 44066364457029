<template>
    <div>
        <b-btn @click="showModal" variant="light" pill>
            <font-awesome-icon icon="fa-duotone fa-info-circle" />
            Help
        </b-btn>

        <b-modal v-model="modal" hide-footer centered title="Video Help">
            <creator-video-guide :modal="true" />
            <b-btn
                @click="modal = false"
                variant="primary"
                pill
                block
                size="lg"
                class="mt-3"
            >
                Ok, got it
            </b-btn>
        </b-modal>
    </div>
</template>

<script>
import CreatorVideoGuide from "@/components/creator/video/CreatorVideoGuide";

export default {
    data() {
        return {
            modal: false,
        };
    },
    methods: {
        showModal() {
            this.modal = true;
        },
    },
    components: {
        CreatorVideoGuide,
    },
};
</script>