
<template>
    <b-btn
        v-if="currentCreatorVideo?.user"
        class="action-button my-2 opacity-100"
    >
        <user-round-avatar
            :standardMap="currentCreatorVideo.user"
            :nonPrimaryPhotos="false"
            :hideBadges="true"
            :userBetweenStatus="false"
            size="small"
        />
    </b-btn>
</template>



<script>
import { mapState } from "vuex";

import UserRoundAvatar from "@/components/user/UserRoundAvatar";

export default {
    computed: {
        ...mapState("videoPlayer", ["currentCreatorVideo"]),
    },
    components: {
        UserRoundAvatar,
    },
};
</script>
