import api from "@/service/api";

const state = () => ({
    page: 1,
    creatorVideos: null,
    currentSlidePosition: null,
    slug: null,
    reachedTheEnd: false,
    hasMorePages: false,
    hasRefreshedFavorites: false,
    hasRefreshedSentGiftIds: false,
    sharing: false,
    gifting: false,
    favoriteCreatorVideoIds: [],
    giftedCreatorVideoIds: [],
    sentGiftIds: [],
    cachedResponses: {},
    isPlaying: true,
    isMuted: true,
    progress: 0,
    currentTime: 0,
})


const mutations = {
    reset(state) {
        state.progress = 0
        state.currentTime = 0
        state.sharing = false
        state.gifting = false
    },
    setPage(state, value) {
        state.page = value
    },
    setSlug(state, value) {
        state.slug = value
    },
    setCurrentTime(state, value) {
        state.currentTime = value
    },
    setReachedTheEnd(state, value) {
        state.reachedTheEnd = value
    },
    setSharing(state, value) {
        if (value) {
            state.gifting = false
        }

        state.sharing = value
    },
    setGifting(state, value) {
        if (value) {
            state.sharing = false
        }

        state.gifting = value
    },
    setHasRefreshedFavorites(state, value) {
        state.hasRefreshedFavorites = value
    },
    setHasRefreshedSentGiftIds(state, value) {
        state.hasRefreshedSentGiftIds = value
    },
    setHasMorePages(state, value) {
        state.hasMorePages = value;
    },
    setProgress(state, value) {
        state.progress = value;
    },
    setIsPlaying(state, value) {
        state.isPlaying = value;
    },
    setIsMuted(state, value) {
        state.isMuted = value;
    },
    setCurrentSlidePosition(state, value) {
        state.currentSlidePosition = value
    },
    setCreatorVideos(state, value) {
        state.creatorVideos = value
    },
    setSentGiftIds(state, value) {
        state.sentGiftIds = value
    },
    addSentGiftId(state, value) {
        state.sentGiftIds.push(value)
    },
    setGiftedCreatorVideoIds(state, value) {
        state.giftedCreatorVideoIds = value
    },
    addGiftedCreatorVideoIds(state, value) {
        state.giftedCreatorVideoIds.push(value)
    },
    setFavoriteCreatorVideoIds(state, value) {
        state.favoriteCreatorVideoIds = value
    },
    addFavoriteCreatorVideoId(state, value) {
        state.favoriteCreatorVideoIds.push(value)
    },
    removeFavoriteCreatorVideoId(state, value) {
        state.favoriteCreatorVideoIds = state.favoriteCreatorVideoIds.filter(id => id !== value)
    },
    setCachedResponse(state, { key, value }) {
        state.cachedResponses[key] = value
    },
}

const actions = {
    async startOver({ commit, dispatch }) {
        commit('setPage', 1)
        await dispatch('refresh')
    },
    async next({ state, commit, dispatch }) {
        // page 1 with slug is just that video
        // page 2 is essentially page 1 without slug
        if (state.slug) {
            commit('setSlug', null)
            await dispatch('refresh')
        } else {
            commit('setPage', state.page + 1)
            await dispatch('refresh')
        }
    },
    async prev({ state, commit, dispatch }) {
        if (state.page === 1) {
            return
        }

        commit('setPage', state.page - 1)
        await dispatch('refresh')
    },
    refresh({ commit, dispatch, state }) {
        dispatch('refreshFavorites')
        dispatch('refreshSentGiftIds')

        const handleResponse = (data) => {
            commit('setCreatorVideos', data?.creatorVideos)
            commit('setHasMorePages', data?.hasMorePages)

            if (!data?.creatorVideos?.length) {
                commit('setReachedTheEnd', true)
            }
        }

        return new Promise(resolve => {
            const cacheKey = `${state.page}-${state.slug}`

            if (cacheKey in state.cachedResponses) {
                handleResponse(state.cachedResponses[cacheKey])
                return resolve();
            }

            api.post("/video/index", {
                page: state.page,
                slug: state.slug
            }).then((response) => {
                commit('setCachedResponse', {
                    key: cacheKey,
                    value: response?.data,
                })

                handleResponse(response?.data)
                return resolve();
            })
        })
    },
    refreshSentGiftIds({ commit, state }) {
        if (state.hasRefreshedSentGiftIds) {
            return;
        }

        commit('setHasRefreshedSentGiftIds', true)

        api.post("/video/gifts").then((response) => {
            commit('setSentGiftIds', response?.data?.sentGiftIds)
            commit('setGiftedCreatorVideoIds', response?.data?.giftedCreatorVideoIds)
        })
    },
    refreshFavorites({ commit, state }) {
        if (state.hasRefreshedFavorites) {
            return;
        }

        commit('setHasRefreshedFavorites', true)

        api.post("/video/favorites").then((response) => {
            commit('setFavoriteCreatorVideoIds', response?.data?.creatorVideoIds)
        })
    },
    favorite({ rootState, commit }) {
        if (!rootState.videoPlayer.currentCreatorVideo) {
            console.error('no current video')
            return;
        }

        commit('addFavoriteCreatorVideoId', rootState.videoPlayer.currentCreatorVideo.creatorVideo.id)

        api.post(`/video/${rootState.videoPlayer.currentCreatorVideo.creatorVideo.id}/favorite`);
    },
    unfavorite({ rootState, commit }) {
        if (!rootState.videoPlayer.currentCreatorVideo) {
            console.error('no current video')
            return;
        }

        commit('removeFavoriteCreatorVideoId', rootState.videoPlayer.currentCreatorVideo.creatorVideo.id)

        api.post(`/video/${rootState.videoPlayer.currentCreatorVideo.creatorVideo.id}/unfavorite`);
    },
    view({ state, rootState }) {
        if (!rootState.videoPlayer.currentCreatorVideo) {
            console.error('no current video')
            return;
        }

        api.post(`/video/${rootState.videoPlayer.currentCreatorVideo.creatorVideo.id}/view`, {
            // improvement, dont round this and use more precise time in the future
            duration: parseInt(state.currentTime)
        });
    },
    ended({ rootState }) {
        if (!rootState.videoPlayer.currentCreatorVideo) {
            console.error('no current video')
            return;
        }

        api.post(`/video/${rootState.videoPlayer.currentCreatorVideo.creatorVideo.id}/ended`);
    },
    setCurrentSlidePosition({ commit, state }, currentSlidePosition) {
        commit('setCurrentSlidePosition', currentSlidePosition)

        // on initial load swiper will almost always load first
        if (state.creatorVideos?.[currentSlidePosition]) {
            commit('reset')

            commit(
                'videoPlayer/setCurrentCreatorVideo',
                state.creatorVideos?.[currentSlidePosition],
                { root: true }
            )
        }
    },
}

export default {
    namespaced: true,
    state,
    actions,
    mutations
}
