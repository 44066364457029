<template>
    <generic-page>
        <div class="mt-2">
            <affiliate-earnings-summary />
            <affiliate-payouts-summary />
            <affiliate-bonus-summary />
            <affiliate-links-summary />
            <affiliate-qrcode-summary />
            <affiliate-photos-summary />
            <affiliate-videos-summary />
            <affiliate-help-summary />
        </div>
    </generic-page>
</template>

<script>
import AffiliateBonusSummary from "@/components/affiliate/summary/AffiliateBonusSummary";
import AffiliateEarningsSummary from "@/components/affiliate/summary/AffiliateEarningsSummary";
import AffiliateLinksSummary from "@/components/affiliate/summary/AffiliateLinksSummary";
import AffiliateQrcodeSummary from "@/components/affiliate/summary/AffiliateQrcodeSummary";
import AffiliatePhotosSummary from "@/components/affiliate/summary/AffiliatePhotosSummary";
import AffiliateVideosSummary from "@/components/affiliate/summary/AffiliateVideosSummary";
import AffiliateHelpSummary from "@/components/affiliate/summary/AffiliateHelpSummary";
import AffiliatePayoutsSummary from "@/components/affiliate/summary/AffiliatePayoutsSummary";

export default {
    created() {
        this.$store.dispatch("affiliate/refresh");
    },
    components: {
        AffiliateEarningsSummary,
        AffiliateBonusSummary,
        AffiliateLinksSummary,
        AffiliateQrcodeSummary,
        AffiliatePhotosSummary,
        AffiliateVideosSummary,
        AffiliateHelpSummary,
        AffiliatePayoutsSummary,
    },
};
</script>