<template>
    <div class="position-relative">
        <h4
            v-if="
                creatorVideo.message && !sharing && !gifting && !withoutMessage
            "
            class="position-absolute text-center glass glass-smoke mx-auto p-1 px-2 mb-0"
            style="top: 0; width: 100%; z-index: 1"
        >
            {{ creatorVideo.message }}
        </h4>
        <video-player
            v-if="simplePlayer"
            :class="{ 'img-fluid': fluid }"
            :muted="isMuted"
            :controls="true"
            :autoplay="true"
            :loop="true"
            :creatorVideo="creatorVideo"
            :poster="creatorVideo.previewUrl"
            :mp4Url="creatorVideo.mp4Url"
            :webmUrl="creatorVideo.webmUrl"
        />
        <video-js-player
            v-else
            :class="{ 'img-fluid': fluid }"
            :muted="isMuted"
            :controls="true"
            :autoplay="true"
            :loop="true"
            :creatorVideo="creatorVideo"
            :poster="creatorVideo.previewUrl"
            :mp4Url="creatorVideo.mp4Url"
            :webmUrl="creatorVideo.webmUrl"
        />
    </div>
</template>

<script>
import { mapState } from "vuex";

import VideoJsPlayer from "@/components/video/VideoJsPlayer";
import VideoPlayer from "@/components/utility/VideoPlayer";

export default {
    computed: {
        ...mapState("videoIndex", ["sharing", "gifting", "isMuted"]),
    },
    components: {
        VideoJsPlayer,
        VideoPlayer,
    },
    props: ["creatorVideo", "fluid", "simplePlayer", "withoutMessage"],
};
</script>