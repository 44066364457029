<template>
    <generic-page>
        <back-to-dashboard />

        <div class="d-flex align-items-center justify-content-between mb-1">
            <h5 class="mb-0">
                {{ $t("Galleries") }}
            </h5>
        </div>

        <b-alert variant="warning" show>
            The gallery feature is being phased out, and can no longer be
            created. You can continue to earn sales from older users, however,
            new users can no longer see galleries in the app.

            <h5 class="mt-3">Requests Replaces Gallery</h5>
            The request feature allows users to request custom content from
            creators, with a maximum request amount of 1000 credits.
        </b-alert>

        <b-alert
            variant="danger"
            show
            v-if="insufficientCredits"
            class="small mt-3"
        >
            <div class="d-flex justify-content-between align-items-center">
                <h5 class="mb-0">Not enough credits</h5>
                <wallet-balance />
            </div>
            <div class="mt-2">
                <coin-badge :amount="prices.galleryDeposit" /> is required to
                create a gallery
            </div>
        </b-alert>

        <div class="py-2">
            <b-list-group>
                <b-list-group-item
                    :key="creatorGallery.id"
                    v-for="creatorGallery in creatorGalleries"
                    @click="
                        $router.push({
                            name: 'creatorGalleryView',
                            params: {
                                id: creatorGallery.id,
                            },
                        })
                    "
                    action
                    class="d-flex align-items-center justify-content-between"
                >
                    <div>
                        <font-awesome-icon
                            icon="fa-solid fa-circle"
                            :class="`text-${creatorGallery.variant}`"
                            size="xs"
                        />
                        {{ creatorGallery.idealName }}
                        <div class="small text-secondary d-flex">
                            <live-timestamp
                                class="mr-2"
                                :timestamp="creatorGallery.created"
                            />
                            <div v-if="creatorGallery.itemCount">
                                {{ creatorGallery.itemCount }}
                                {{
                                    $t("item")
                                        | pluralize(creatorGallery.itemCount)
                                }}
                            </div>
                        </div>
                    </div>

                    <coin-badge
                        v-if="creatorGallery.price"
                        class="border"
                        :amount="creatorGallery.price"
                        :label="$t('Price')"
                    >
                    </coin-badge>
                </b-list-group-item>
            </b-list-group>
        </div>
    </generic-page>
</template>

<script>
import { mapState } from "vuex";

import BackToDashboard from "@/components/utility/BackToDashboard";
import WalletBalance from "@/components/utility/WalletBalance";

export default {
    created() {
        this.$store.dispatch("creatorGallery/refresh");
    },
    computed: {
        ...mapState("creatorGallery", [
            "creatorGalleries",
            "insufficientCredits",
        ]),
        ...mapState("options", ["prices"]),
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
    },
    methods: {
        createGallery() {
            this.$store.dispatch("creatorGallery/create");
        },
        async hideGalleryPricingGuide() {
            await this.api.post("/userFlag/creatorFinishedGalleryGuide");
            this.$store.dispatch("onboarding/status");
        },
    },
    components: {
        BackToDashboard,
        WalletBalance,
    },
};
</script>