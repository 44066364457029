<template>
    <div>
        <creator-video-guide />

        <b-btn
            v-if="!modal"
            @click="finished"
            variant="primary"
            pill
            block
            size="lg"
            class="mt-3"
        >
            Ok, got it
        </b-btn>
    </div>
</template>

<script>
import CreatorVideoGuide from "@/components/creator/video/CreatorVideoGuide";

export default {
    methods: {
        finished() {
            this.api.post("/userFlag/creatorGuideVideo").then(() => {
                this.$store.dispatch("onboarding/status");
                this.$store.dispatch("creatorGuide/refresh");
                this.$store.commit("creatorGuide/reset");
            });
        },
    },
    components: {
        CreatorVideoGuide,
    },
    props: ["modal"],
};
</script>


