<template>
    <div>
        <video
            ref="videoPlayer"
            class="video-js vjs-default-skin img-fluid"
            :autoplay="autoplay"
            :loop="loop"
            playsinline
            @timeupdate="updateProgress"
        >
            <source :src="webmUrl" type="video/webm" />
            <source :src="mp4Url" type="video/mp4" />
        </video>
    </div>
</template>

<style lang="scss" scoped>
// layout issues when using absolute
// custom toolbar so not needed
.video-js .vjs-tech {
    position: relative !important;
}
</style>

<script>
import videojs from "video.js";
import "video.js/dist/video-js.css";
import { mapState } from "vuex";

export default {
    data() {
        return {
            player: null,
        };
    },
    async mounted() {
        this.player = videojs(this.$refs.videoPlayer, {
            muted: this.muted,
            controls: false, // Disable default controls since we'll use custom progress
            autoplay: this.autoplay,
            loop: this.loop,
        });

        // Play/pause based on initial `play` prop value
        if (this.play) {
            await this.player.play();
        } else {
            await this.player.pause();
        }

        // Set initial muted state
        await this.player.muted(this.muted);
    },
    watch: {
        isPlaying() {
            this.refreshPlayState();
        },
        currentCreatorVideoId() {
            this.refreshPlayState();
        },
        async muted(newValue) {
            await this.player.muted(newValue);
        },
    },
    computed: {
        currentCreatorVideoId() {
            return this.currentCreatorVideo?.creatorVideo?.id;
        },
        ...mapState("videoIndex", ["isPlaying"]),
        ...mapState("videoPlayer", ["currentCreatorVideo"]),
    },
    methods: {
        async refreshPlayState() {
            if (
                this.isPlaying &&
                this.currentCreatorVideoId === this.creatorVideo.id
            ) {
                await this.player.play();
            } else {
                await this.player.pause();
            }
        },
        updateProgress() {
            let duration = this.player.duration();

            if (!duration || !isFinite(duration)) {
                duration = this.currentCreatorVideo?.creatorVideo?.duration;
            }

            const currentTime = this.player.currentTime();

            if (currentTime >= duration - 0.3) {
                this.$store.dispatch("videoIndex/ended");
            }

            if (this.player && duration) {
                const progress = (currentTime / duration) * 100;
                this.$store.commit("videoIndex/setProgress", progress);
                this.$store.commit("videoIndex/setCurrentTime", currentTime);
            }
        },
    },
    async beforeDestroy() {
        if (this.player) {
            await this.player.dispose();
        }
    },
    props: [
        "autoplay",
        "controls",
        "loop",
        "webmUrl",
        "mp4Url",
        "play",
        "muted",
        "creatorVideo",
    ],
};
</script>

