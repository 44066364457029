<template>
    <div v-if="creatorVideo.thumbnailUrl" class="d-flex align-items-center">
        <div class="mr-2">
            <div class="position-relative">
                <img
                    class="img-fluid rounded"
                    :src="creatorVideo.thumbnailUrl"
                />
                <div
                    class="position-absolute"
                    style="
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                    "
                >
                    <font-awesome-icon
                        icon="fa-duotone fa-circle-play"
                        style="color: white"
                        size="3x"
                    />
                </div>
            </div>
        </div>
        <div class="flex-grow-1">
            <div class="lead">
                {{ creatorVideo.message }}
            </div>
            <div class="small text-secondary mt-2">
                {{ creatorVideo.publishDateTerse }}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ["creatorVideo"],
};
</script>