<template>
    <div>
        <div class="py-3">
            <h3 class="text-center">Welcome Filipino4U Members!</h3>
            <div>
                Filipino4U.com is now Filipina.app, get ready for an exciting
                new experience and connect with brand new people on our updated
                website. Enjoy live streaming, video calling and live chatting
                in a totally new platform with the same great users
            </div>

            <h5 class="mt-3">Is My Account Still Active?</h5>
            <div>
                Yes, your account is still active! Feel free to browse around
                and get to know the new platform, and maybe meet that special
                someone!
            </div>

            <b-row class="mt-4">
                <b-col cols="12" md="6" class="my-2">
                    <b-btn
                        variant="primary"
                        @click="closeModal"
                        pill
                        block
                        size="lg"
                    >
                        {{ $t("Ok, got it") }}
                    </b-btn>
                </b-col>
                <b-col cols="12" md="6" class="my-2">
                    <b-btn
                        variant="secondary"
                        @click="maybeLater"
                        pill
                        block
                        size="lg"
                    >
                        {{ $t("Remind me later") }}
                    </b-btn>
                </b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
export default {
    computed: {
        currentOffer() {
            return this.$store.state.displayOffer?.currentOffer;
        },
    },
    methods: {
        closeModal() {
            this.$store.commit("interface/setDisplayOfferModalVisible", false);
        },
        maybeLater() {
            this.api.post("/offer/removeViewedOffer", {
                offer: this.currentOffer.offer,
            });

            this.$store.commit("interface/setDisplayOfferModalVisible", false);
        },
    },
};
</script>