import api from "@/service/api";

const state = () => ({
    page: 1,
    creatorVideos: null,
    currentSlidePosition: null,
    slug: null,
    reachedTheEnd: false,
    hasMorePages: false,
    cachedResponses: {},
    isPlaying: true,
    isMuted: true,
    progress: 0,
    currentTime: 0,
})


const mutations = {
    reset(state) {
        state.progress = 0
        state.currentTime = 0
        state.sharing = false
        state.gifting = false
    },
    setPage(state, value) {
        state.page = value
    },
    setSlug(state, value) {
        state.slug = value
    },
    setCurrentTime(state, value) {
        state.currentTime = value
    },
    setReachedTheEnd(state, value) {
        state.reachedTheEnd = value
    },
    setSharing(state, value) {
        if (value) {
            state.gifting = false
        }

        state.sharing = value
    },
    setHasMorePages(state, value) {
        state.hasMorePages = value;
    },
    setProgress(state, value) {
        state.progress = value;
    },
    setIsPlaying(state, value) {
        state.isPlaying = value;
    },
    setIsMuted(state, value) {
        state.isMuted = value;
    },
    setCurrentSlidePosition(state, value) {
        state.currentSlidePosition = value
    },
    setCreatorVideos(state, value) {
        state.creatorVideos = value
    },
    setCachedResponse(state, { key, value }) {
        state.cachedResponses[key] = value
    },
}

const actions = {
    async startOver({ commit, dispatch }) {
        commit('setPage', 1)
        await dispatch('refresh')
    },
    async next({ state, commit, dispatch }) {
        // page 1 with slug is just that video
        // page 2 is essentially page 1 without slug
        if (state.slug) {
            commit('setSlug', null)
            await dispatch('refresh')
        } else {
            commit('setPage', state.page + 1)
            await dispatch('refresh')
        }
    },
    async prev({ state, commit, dispatch }) {
        if (state.page === 1) {
            return
        }

        commit('setPage', state.page - 1)
        await dispatch('refresh')
    },
    refresh({ commit, state }) {
        const handleResponse = (data) => {
            commit('setCreatorVideos', data?.creatorVideos)
            commit('setHasMorePages', data?.hasMorePages)

            if (!data?.creatorVideos?.length) {
                commit('setReachedTheEnd', true)
            }
        }

        return new Promise(resolve => {
            const cacheKey = `${state.page}-${state.slug}`

            if (cacheKey in state.cachedResponses) {
                handleResponse(state.cachedResponses[cacheKey])
                return resolve();
            }

            api.post("/publicVideo/index", {
                page: state.page,
                slug: state.slug
            }).then((response) => {
                commit('setCachedResponse', {
                    key: cacheKey,
                    value: response?.data,
                })

                handleResponse(response?.data)
                return resolve();
            })
        })
    },
    setCurrentSlidePosition({ commit, state }, currentSlidePosition) {
        commit('setCurrentSlidePosition', currentSlidePosition)

        // on initial load swiper will almost always load first
        if (state.creatorVideos?.[currentSlidePosition]) {
            commit('reset')

            commit(
                'videoPlayer/setCurrentCreatorVideo',
                state.creatorVideos?.[currentSlidePosition],
                { root: true }
            )
        }
    },
}

export default {
    namespaced: true,
    state,
    actions,
    mutations
}
