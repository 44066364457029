<template>
    <div>
        <div v-if="appReadyToStart">
            <div v-if="onboardingUser">
                <div v-if="hasIncompleteSteps">
                    <onboarding-app />
                </div>
                <div v-else>
                    <social-app />
                </div>
            </div>
            <div v-else-if="isPendingLogin" class="text-center mt-5">
                <brand-logo-splash />
                <generic-loading />
            </div>
            <div v-else>
                <public-app />
            </div>
        </div>
        <div v-else>
            <generic-loading />
            <app-loading-failure />
        </div>
    </div>
</template>

<style lang="scss">
@import "@/scss/dark.scss";

@import "~bootstrap/scss/bootstrap.scss";
@import "~bootstrap-vue/src/index.scss";
@import "@/scss/app.scss";
</style>

<script>
import { mapGetters, mapState } from "vuex";

import SocialApp from "@/app/SocialApp";
import OnboardingApp from "@/app/OnboardingApp";
import PublicApp from "@/app/PublicApp";

import VisibilityMixin from "@/mixin/VisibilityMixin";
import KeyboardMixin from "@/mixin/KeyboardMixin";
import ScrollPositionMixin from "@/mixin/ScrollPositionMixin";
import DeeplinkMixin from "@/mixin/DeeplinkMixin";
import BrandLogoSplash from "@/components/brand/BrandLogoSplash";
import AppLoadingFailure from "@/components/utility/AppLoadingFailure";

export default {
    mixins: [
        ScrollPositionMixin,
        VisibilityMixin,
        KeyboardMixin,
        DeeplinkMixin,
    ],
    mounted() {
        this.$store.dispatch("startup/run");

        if (
            process.env.VUE_APP_TARGET === "cordova" &&
            process.env.NODE_ENV === "development"
        ) {
            (function () {
                var s = document.createElement("script");
                s.src = "https://remotejs.com/agent/agent.js";
                s.setAttribute(
                    "data-consolejs-channel",
                    "db8dc120-d4c1-42da-88df-c464ec8a5962"
                );
                document.head.appendChild(s);
            })();
        }
    },
    computed: {
        isPendingLogin() {
            return this.authToken || this.hasLoginToken;
        },
        appTitle() {
            return this.brand?.name ?? "Loading...";
        },
        ...mapState("brand", ["brand"]),
        ...mapState("system", ["appReadyToStart"]),
        ...mapState("startup", ["hasLoginToken"]),
        ...mapState("onboarding", ["hasIncompleteSteps"]),
        ...mapState("user", ["authToken"]),
        ...mapGetters("system", ["appReadyToStart"]),
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
    },
    components: {
        SocialApp,
        OnboardingApp,
        PublicApp,
        BrandLogoSplash,
        AppLoadingFailure,
    },
    metaInfo() {
        const link = [];

        if (this.brand) {
            link.push({
                rel: "icon",
                type: "image/jpeg",
                sizes: "100x100",
                href: this.brand.logo100,
            });

            link.push({
                rel: "apple-touch-icon",
                href: this.brand.logo100,
            });
        }

        return {
            title: this.appTitle,
            link,
        };
    },
};
</script>
        