<template>
    <div>
        <b-alert v-if="error" variant="danger" show>
            {{ error }}
        </b-alert>
        <div class="p-2 d-flex align-items-center justify-content-between">
            <div class="d-flex align-items-center">
                <b-dropdown
                    variant="light"
                    toggle-class="rounded-pill"
                    no-caret
                    class="ml-2"
                >
                    <template #button-content>
                        <font-awesome-icon icon="fa-solid fa-ellipsis" />
                    </template>

                    <b-dropdown-item
                        @click="deleteVideo"
                        link-class="text-danger"
                    >
                        Delete
                    </b-dropdown-item>
                </b-dropdown>
            </div>
            <div>
                <b-btn
                    :variant="recentlySaved ? 'success' : 'primary'"
                    size="lg"
                    @click="publishPost"
                    tabindex="10"
                    class="px-5"
                    pill
                >
                    <span v-if="recentlySaved">
                        <font-awesome-icon icon="fa-light fa-check-circle" />
                    </span>
                    <span v-else-if="creatorVideo.published"> Save </span>
                    <span v-else> Publish </span>
                </b-btn>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    data() {
        return {
            message: null,
        };
    },
    computed: {
        ...mapState("creatorVideo", [
            "creatorVideo",
            "publishInProgress",
            "updateInProgress",
            "createInProgress",
            "uploadQueue",
            "recentlySaved",
            "error",
            "uploadInProgress",
        ]),
    },
    methods: {
        cancelPost() {
            this.$store.commit("creatorVideo/reset");
        },
        publishPost() {
            this.$store.dispatch("creatorVideo/publish");
        },
        deleteVideo() {
            this.$store.dispatch("creatorVideo/delete");
        },
    },
    components: {},
};
</script>