

<template>
    <div v-if="currentCreatorVideo">
        <div class="p-2">
            <b-btn
                @click="togglePlay"
                class="action-button glass glass-smoke my-2"
            >
                <font-awesome-icon
                    v-if="isPlaying"
                    icon="fa-solid fa-pause"
                    size="xl"
                />
                <font-awesome-icon v-else icon="fa-solid fa-play" size="xl" />
            </b-btn>
            <b-btn
                @click="toggleMute"
                class="action-button glass glass-smoke my-2 ml-3"
            >
                <font-awesome-icon
                    v-if="isMuted"
                    icon="fa-solid fa-volume-slash"
                    size="xl"
                />
                <font-awesome-icon v-else icon="fa-solid fa-volume" size="xl" />
            </b-btn>
        </div>
        <div v-if="progress">
            <b-progress max="100" height="3px" style="background-color: #000">
                <b-progress-bar :style="progressBarStyle" :value="progress">
                </b-progress-bar>
            </b-progress>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    data() {
        return {
            progressBarStyle: {
                background: "linear-gradient(90deg, blue, red)",
            },
        };
    },
    computed: {
        ...mapState("videoPlayer", ["currentCreatorVideo"]),
        ...mapState("videoIndex", ["isPlaying", "isMuted", "progress"]),
    },
    methods: {
        toggleMute() {
            this.$store.commit("videoIndex/setIsMuted", !this.isMuted);
        },
        togglePlay() {
            this.$store.commit("videoIndex/setIsPlaying", !this.isPlaying);
        },
    },
};
</script>
